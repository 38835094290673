import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import { ROUTE_DEFAULT } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { COUNTRIES } from '../../constants';
import { CORPORATE_CLIENT_ACCOUNT_TYPES } from 'constants/corporateClients.js';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import Authorize from 'components/Providers/Authorize';
import PageTitle from 'components/Shared/Page/Title';
import tableColumns from './tableColumns';

const CorporationSelector = ({
  user: { corporations },
  changeCorporateClient,
  loadCurrentProfile,
  history,
}) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const handleChange = async record => {
    const selectedCorporation = corporations.find(corp => corp.id === record.id);
    const currentRole = record.userRole;
    const additionalProperties = {
      company: {
        name: record.corporateClientName,
        id: record.id,
        country: COUNTRIES[record.country].label,
        status: record.statusName,
        accountType: record.isTestCorporation
          ? CORPORATE_CLIENT_ACCOUNT_TYPES.TEST
          : CORPORATE_CLIENT_ACCOUNT_TYPES.STANDARD,
        registrationType: record.registrationType,
      },
    };

    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountEnter, additionalProperties);

    await changeCorporateClient(record.id, selectedCorporation, currentRole);
    await loadCurrentProfile();

    await history.push(ROUTE_DEFAULT);
  };

  useEffect(() => {
    changeCorporateClient(null);
  }, [changeCorporateClient]);

  return (
    <Authorize>
      <Helmet title="Corporations" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <PageTitle title={translateText('corporateAccounts.corporations.title')} />
            <div className="card-body">
              <Table
                data-testid="corporationSelectorTable"
                scroll={{ x: true }}
                className="utils__scrollTable"
                loading={false}
                columns={tableColumns(handleChange, translateText)}
                rowKey={record => record.id}
                dataSource={corporations}
              />
            </div>
          </div>
        </div>
      </div>
    </Authorize>
  );
};

export default connect(
  ({ user }) => ({ user }),
  dispatch => ({
    changeCorporateClient: (
      corporateClientId,
      currentCorporation,
      corporationRole,
      restrictedCorporateClientProspect,
      emailNotVerified,
    ) => {
      return dispatch({
        type: 'user/SET_STATE',
        payload: {
          corporateClientId,
          currentCorporation,
          corporationRole,
          restrictedCorporateClientProspect,
          emailNotVerified,
        },
      });
    },
    loadCurrentProfile: () => {
      dispatch({ type: 'user/LOAD_CURRENT_PROFILE' });
    },
  }),
)(withRouter(CorporationSelector));
