export const CREDIT_ACCOUNT_TYPES = {
  CREDIT_ACCOUNT: 'creditAccount',
  CREDIT_BUILDER: 'creditBuilder',
  HYBRID: 'hybrid',
};

export const CREDIT_ACCOUNT_TYPES_MAPPING = {
  creditAccount: CREDIT_ACCOUNT_TYPES.CREDIT_ACCOUNT,
  creditBuilder: CREDIT_ACCOUNT_TYPES.CREDIT_BUILDER,
  hybrid: CREDIT_ACCOUNT_TYPES.HYBRID,
};

export const CREDIT_ACCOUNT_STATUS = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  ON_HOLD: 'onHold',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

export const CREDIT_ACCOUNT_STATUS_MAPPING = {
  active: CREDIT_ACCOUNT_STATUS.ACTIVE,
  closed: CREDIT_ACCOUNT_STATUS.CLOSED,
  onHold: CREDIT_ACCOUNT_STATUS.ON_HOLD,
  pending: CREDIT_ACCOUNT_STATUS.PENDING,
  rejected: CREDIT_ACCOUNT_STATUS.REJECTED,
};

export const CREDIT_ACCOUNT_MIGRATION_STATUS = {
  STARTED: 'started',
  NOT_STARTED: 'notStarted',
};

export const CREDIT_ACCOUNT_MIGRATION_STATUS_MAPPING = {
  started: CREDIT_ACCOUNT_MIGRATION_STATUS.STARTED,
  notStarted: CREDIT_ACCOUNT_MIGRATION_STATUS.NOT_STARTED,
};
