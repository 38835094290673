import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Icon, { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { COUNTRIES } from '../../../constants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { ROUTE_DEFAULT, ROUTE_CORPORATIONS_CREATE, ROUTE_CORPORATIONS_EDIT } from 'constants.js';
import { CORPORATE_CLIENT_ACCOUNT_TYPES } from 'constants/corporateClients.js';
import { setUserProperties } from 'utils/amplitudeUtil';
import { setHotjarUserAttributes } from 'utils/hotjarUtil';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import Authorize from 'components/Providers/Authorize';
import ButtonSearchRow from 'components/Shared/Page/ButtonSearchRow';
import PageTitle from 'components/Shared/Page/Title';
import userActions from 'redux/user/actions';
import corporationActions from 'redux/corporations/actions';
import corporationAdminHelper from './helper';
import tableColumns from './tableColumns';
import useCorporationsAdmin from './useCorporationsAdmin';
import CorporationsTable from './CorporationsTable';
import useCreditAccountMigration from './useCreditAccountMigration';
import Spinner from 'components/Shared/Spinner';

const CorporationsAdmin = ({ history }) => {
  const {
    corporations,
    totalSize,
    currentPage,
    tableFilters,
    selectedTableRows,
    isCorporationsLoading,
    onPageSizeChange,
    onPageChange,
    onRowSelectChange,
    onSearch,
    onTableChange,
    onDeleteCorporation,
    onDeactivate,
    onReactivate,
  } = useCorporationsAdmin();

  const { sendAmplitudeEvent } = useAmplitude();
  const dispatch = useDispatch();
  const { showFeature } = useFeatureFlag();

  const { id: userId, corporateClientId, corporationRole, currentCorporation } = useSelector(
    state => state.user,
  );
  const { locale } = useSelector(state => state.settings);

  const { translateText } = useTranslation();
  const {
    handleCreditAccountMigration,
    isCreditAccountMigrationLoading,
  } = useCreditAccountMigration();

  const menuItems = corporation => {
    const additionalProperties = {
      company: {
        name: corporation.corporateClientName,
        id: corporation.id,
        country: COUNTRIES[corporation?.country].label,
      },
    };

    const handleDeactivate = () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountDeactivate, additionalProperties);
      corporationAdminHelper.deactivateCorporation(corporation, onDeactivate, translateText);
    };

    const handleReactivate = () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountReactivate, additionalProperties);
      corporationAdminHelper.reactivateCorporation(corporation, onReactivate, translateText);
    };

    const handleDelete = () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountDelete, additionalProperties);
      corporationAdminHelper.deleteCorporation(onDeleteCorporation, corporation, translateText);
    };

    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleEditCorporation(corporation)}>
          {translateText('corporateAccounts.actions.edit')}
        </Menu.Item>
        {corporationAdminHelper.canDeactivate(corporation) && (
          <Menu.Item key="3" onClick={handleDeactivate}>
            {translateText('corporateAccounts.actions.deactivate')}
          </Menu.Item>
        )}
        {corporationAdminHelper.canReactivate(corporation) && (
          <Menu.Item key="4" onClick={handleReactivate}>
            {translateText('corporateAccounts.actions.reactivate')}
          </Menu.Item>
        )}
        {corporationAdminHelper.canDelete(corporation) && (
          <Menu.Item key="5" onClick={handleDelete} danger>
            {translateText('corporateAccounts.actions.delete')}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleChange = corporation => {
    const additionalProperties = {
      company: {
        name: corporation.corporateClientName,
        id: corporation.id,
        country: COUNTRIES[corporation.country].label,
        status: corporation.statusName,
        accountType: corporation.isTestCorporation
          ? CORPORATE_CLIENT_ACCOUNT_TYPES.TEST
          : CORPORATE_CLIENT_ACCOUNT_TYPES.STANDARD,
        registrationType: corporation.registrationType,
      },
    };

    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountEnter, additionalProperties);

    dispatch({
      type: userActions.SET_STATE,
      payload: {
        corporateClientId: corporation && corporation.id,
        currentCorporation: corporation,
      },
    });
    history.push(ROUTE_DEFAULT);
  };

  const handleCorporationCreate = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.addNewAccount);
    dispatch({ type: userActions.SET_STATE, payload: { isCreatingCorp: true } });
    history.push(ROUTE_CORPORATIONS_CREATE);
  };

  const handleEditCorporation = corporation => {
    const additionalProperties = {
      company: {
        name: corporation.corporateClientName,
        id: corporation.id,
        country: COUNTRIES[corporation?.country].label,
      },
    };

    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountEdit, additionalProperties);

    dispatch({
      type: corporationActions.GET_BY_ID,
      payload: { id: corporation.id },
    });

    history.push(ROUTE_CORPORATIONS_EDIT);
  };

  const handleMigration = () => {
    handleCreditAccountMigration(selectedTableRows.map(row => row.id));
  };

  useEffect(() => {
    if (corporateClientId)
      dispatch({
        type: userActions.SET_STATE,
        payload: {
          corporateClientId: null,
          currentCorporation: null,
        },
      });
  }, [corporateClientId, dispatch]);

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserProperties({ role: corporationRole });
    setHotjarUserAttributes({
      userId,
      language: locale,
      country: currentCorporation?.country,
      role: corporationRole,
      isTestCorporation: currentCorporation?.isTestCorporation ?? false,
    });
  }, [userId, corporationRole, locale, currentCorporation]);

  return (
    <Authorize redirect to={ROUTE_DEFAULT}>
      <Helmet title="Admin Corporations" />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <PageTitle
              title={translateText('corporateAccounts.admin.accounts')}
              numRecords={totalSize}
            />
            <ButtonSearchRow
              visible
              showLengthRequired={false}
              searchOptions={{
                handleSearch: onSearch,
                placeholder: translateText('corporateAccounts.admin.search'),
              }}
              primaryBtn={
                <Button
                  type="primary"
                  size="large"
                  onClick={handleCorporationCreate}
                  data-testid="createCorporation"
                >
                  <PlusOutlined />
                  {translateText('corporateAccounts.actions.addNewAccount')}
                </Button>
              }
              secondaryBtn={
                showFeature('creditAccount') && (
                  <Button
                    type="secondary"
                    size="large"
                    onClick={handleMigration}
                    style={{ minWidth: '102px' }}
                    data-testid="migrate"
                    disabled={false}
                    icon={
                      !isCreditAccountMigrationLoading && (
                        <Icon component={SwapOutlined} style={{ marginRight: '-2px' }} />
                      )
                    }
                  >
                    {isCreditAccountMigrationLoading ? (
                      <Spinner />
                    ) : (
                      translateText('corporateAccounts.actions.migrate')
                    )}
                  </Button>
                )
              }
            />
            <CorporationsTable
              columns={tableColumns(
                menuItems,
                handleChange,
                tableFilters,
                corporations,
                translateText,
              )}
              currentPage={currentPage}
              dataSource={corporations}
              loading={isCorporationsLoading}
              totalCount={totalSize}
              visible={true}
              selectedTableRows={selectedTableRows}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onTableChange={onTableChange}
              onRowSelectChange={onRowSelectChange}
            />
          </div>
        </div>
      </div>
    </Authorize>
  );
};

export default withRouter(CorporationsAdmin);
