import { useQuery } from 'react-query';
import creditAccount from 'services/api/creditAccounts';
import notify from 'utils/notificationUtils';
import useTranslation from 'utils/hooks/useTranslation';

export const useGetCreditAccountDetails = ({ corporateClientId }) => {
  const { translateText } = useTranslation();

  const getCreditAccountDetails = async () => {
    const result = await creditAccount.getCreditAccount(corporateClientId);
    return result?.data;
  };

  return useQuery({
    queryKey: [{ corporateClientId }],
    queryFn: getCreditAccountDetails,
    enabled: !!corporateClientId,
    retry: false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.creditAccount.internalError'),
      });
    },
  });
};
