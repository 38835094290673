import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTES } from 'constants.js';
import userService from 'services/user';
import IndexLayout from 'components/Layout/IndexLayout';
import ErrorBoundary from 'components/Providers/ErrorBoundary';
import CacheBuster from 'components/Providers/CacheBuster';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import NotFoundPage from 'pages/Errors/notFoundPage';
import MaintenancePage from 'pages/Maintenance';
import useTranslation from 'utils/hooks/useTranslation';
import notify from 'utils/notificationUtils';
import useInitializeTrackingServices from 'utils/hooks/useInitializeTrackingServices';
import {
  baseRoutes,
  corporationRoutes,
  adminRoutes,
  businessUserRoutes,
  publicRoutes,
  restrictedCorporateClientProspectRoutes,
} from './routes';
import {
  shouldAttachAdminRoutes,
  shouldAttachBusinessUserRoutes,
  shouldAttachCorpRoutes,
  shouldAttachMaintenanceRoute,
} from './helper';
import { CREDIT_ACCOUNT_STATUS } from 'constants/creditAccount';

const Router = ({
  history,
  authorized,
  loading,
  logout,
  loggedOut,
  currentCorporation,
  adminRole,
}) => {
  const { translateText } = useTranslation();
  const { showFeature } = useFeatureFlag();
  const [sessionExpired, setSessionExpired] = useState(true);
  const [restrictedCorporateClientProspect, setRestrictedCorporateClientProspect] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState(false);

  const blockPaymentsAccess =
    currentCorporation?.creditAccountStatus.toLowerCase() === CREDIT_ACCOUNT_STATUS.ACTIVE &&
    !adminRole;

  useInitializeTrackingServices();

  useEffect(() => {
    userService.verifySessionValid().then(res => {
      setSessionExpired(res);
      if (res && !loggedOut) {
        notify({
          type: 'warning',
          title: translateText('corporateAccounts.auth.sessionExpired'),
          message: translateText('corporateAccounts.auth.redirectMessage'),
        });
        setTimeout(async () => logout(), 3000);
      }
    });
    userService.getRestrictedStatus().then(res => {
      setRestrictedCorporateClientProspect(res.restrictedCorporateClientProspect);
      setEmailNotVerified(res.emailNotVerified);
    });
  });

  if (!sessionExpired) {
    // If showMaintenancePage is true, the MaintenancePage route should override everything else by appearing first in the list of routes and not having a `exact` path, therefore matching all routes
    const showMaintenancePage = shouldAttachMaintenanceRoute(authorized, adminRole);
    return (
      <ErrorBoundary>
        <CacheBuster isUserAuthorized={authorized}>
          <IndexLayout
            showMenu={!showMaintenancePage}
            padContent={!showMaintenancePage}
            showFooter={!showMaintenancePage}
          >
            <Switch>
              {publicRoutes.map(route => (
                <Route {...route} key={route.path} />
              ))}
              {baseRoutes.map(route => (
                <Route {...route} key={route.path} />
              ))}
              <Route
                exact
                path="/"
                render={() => {
                  return <Redirect to={ROUTES.AUTH_REDIRECT} />;
                }}
              />

              {!loggedOut && !loading && showMaintenancePage && (
                <Route component={MaintenancePage} />
              )}

              {shouldAttachAdminRoutes(authorized, adminRole) &&
                adminRoutes.map(route => <Route {...route} key={route.path} />)}

              {shouldAttachBusinessUserRoutes({
                authorized,
                restrictedCorporateClientProspect,
                emailNotVerified,
              }) && businessUserRoutes.map(route => <Route {...route} key={route.path} />)}

              {shouldAttachCorpRoutes({
                authorized,
                currentCorporation,
                restrictedCorporateClientProspect,
                emailNotVerified,
              }) &&
                corporationRoutes.map(route => {
                  if (route.path === ROUTES.PAYMENTS && blockPaymentsAccess) {
                    return null;
                  }

                  if (route.feature && showFeature(route.feature)) {
                    return <Route key={route.path} {...route} component={route.featureComponent} />;
                  }

                  return <Route key={route.path} {...route} component={route.component} />;
                })}

              {restrictedCorporateClientProspect &&
                restrictedCorporateClientProspectRoutes.map(route => (
                  <Route {...route} key={route.path} />
                ))}

              {authorized ? (
                restrictedCorporateClientProspect ? (
                  <Redirect to={ROUTES.DRIVERS} />
                ) : emailNotVerified ? (
                  <Redirect to={ROUTES.VERIFY_EMAIL} />
                ) : (
                  <Route component={NotFoundPage} />
                )
              ) : (
                <Route render={() => <Redirect to={ROUTES.AUTH_REDIRECT} />} />
              )}
            </Switch>
          </IndexLayout>
        </CacheBuster>
      </ErrorBoundary>
    );
  }

  return null;
};

const mapStateToProps = ({ user }) => ({
  authorized: user.authorized,
  loggedOut: user.logout,
  currentCorporation: user.currentCorporation,
  adminRole: user.adminRole,
  loading: user.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    logout: () => dispatch({ type: 'user/LOGOUT' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
