import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import { persistor } from 'initStore';
import localizedMessage from 'services/localize';
import userService from 'services/user';
import { deinitializeAmplitude } from 'utils/amplitudeUtil.js';
import userManager from 'utils/userManager';
import notify from 'utils/notificationUtils';
import actions from './actions';

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      logout: false,
    },
  });

  yield put({
    type: 'user/SET_STATE',
    payload: {
      isActivating: false,
      activationSuccess: false,
    },
  });

  const { currentUser, force } = payload;

  if (currentUser) {
    yield LOAD_CURRENT_ACCOUNT();

    yield put({
      type: 'user/SET_STATE',
      payload: {
        adminRole: yield call(userService.getAdminRole),
        authorized: true,
        logout: false,
      },
    });

    if (!force) {
      notify({
        type: 'success',
        message: localizedMessage('corporateAccounts.user.login.success'),
      });
    } else {
      notify({
        type: 'success',
        message: localizedMessage('corporateAccounts.user.activation.success'),
      });
    }
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        logout: false,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(userService.currentAccount);

  if (response) {
    const {
      userId: id,
      permissions: role,
      corporations,
      corporateClientId,
      currentCorporation,
      restrictedCorporateClientProspect,
      emailNotVerified,
    } = response;

    const commonPayload = {
      role,
      corporateClientId,
      corporations,
      currentCorporation,
      restrictedCorporateClientProspect,
      emailNotVerified,
      adminRole: yield call(userService.getAdminRole),
      authorized: true,
      loading: false,
      logout: false,
    };

    if (emailNotVerified) {
      commonPayload.userId = id;
    } else {
      commonPayload.id = id;
    }

    yield put({
      type: 'user/SET_STATE',
      payload: commonPayload,
    });

    yield LOAD_CURRENT_PROFILE();
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
        loading: false,
        logout: false,
      },
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOAD_CURRENT_PROFILE() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(userService.currentProfile);
  if (response) {
    const { firstName, lastName, role: corporationRole } = response;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        firstName,
        lastName,
        corporationRole,
        loading: false,
        logout: false,
      },
    });
  }
}

export function* LOGOUT() {
  deinitializeAmplitude();

  if (persistor) {
    yield persistor.purge();
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      role: '',
      firstName: '',
      lastName: '',
      corporateClientId: null,
      corporations: [],
      adminRole: false,
      authorized: false,
      loading: false,
      currentCorporation: null,
      logout: true,
    },
  });

  yield userManager.signoutRedirect();
  yield userManager.removeUser();
  yield userManager.clearStaleState();
  yield window.localStorage.clear();
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOAD_CURRENT_PROFILE, LOAD_CURRENT_PROFILE),
    takeEvery(actions.LOGOUT, LOGOUT),
  ]);
  yield LOAD_CURRENT_ACCOUNT();
}
