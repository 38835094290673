import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import useTranslation from 'utils/hooks/useTranslation';
import { CREDIT_ACCOUNT_STATUS } from 'constants/creditAccount';

import styles from './style.module.scss';

const MenuGenerator = ({ testid, theme, onMenuClick, menuItems, selectedKeys, isAdmin }) => {
  const { translateText } = useTranslation();
  const { showFeature } = useFeatureFlag();
  const { creditAccountStatus } = useSelector(state => state.user?.currentCorporation);

  return (
    <Menu
      data-testid={testid}
      theme={theme}
      onClick={onMenuClick}
      selectedKeys={selectedKeys}
      mode="inline"
      className={styles.navigation}
    >
      {menuItems.map(menuItem => {
        if (
          menuItem.key === 'payments' &&
          creditAccountStatus?.toLowerCase() === CREDIT_ACCOUNT_STATUS.ACTIVE &&
          !isAdmin
        ) {
          return null;
        }

        const menuLink = (
          <Link
            to={menuItem.url}
            data-testid={
              selectedKeys.includes(menuItem.key) ? `selectedMenuItem-${menuItem.key}` : null
            }
          >
            <Icon component={menuItem.component} className={`${styles.icon}`} />
            <span className={styles.title}>
              {translateText(`corporateAccounts.menuLeft.${menuItem.key}`)}
            </span>
          </Link>
        );

        if (menuItem.feature) {
          return (
            showFeature(menuItem.feature) && (
              <Menu.Item key={menuItem.key} disabled={menuItem.disabled}>
                {menuLink}
              </Menu.Item>
            )
          );
        }
        return (
          <Menu.Item key={menuItem.key} disabled={menuItem.disabled}>
            {menuLink}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default MenuGenerator;
