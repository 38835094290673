module.exports = {
  'Corporate Accounts Key': 'String',
  TEST: '',
  'TEST.INTERPOLATIONS': '',
  'corporateAccounts.actions': 'Aktionen',
  'corporateAccounts.actions.addNewAccount': 'Neues Konto hinzufügen',
  'corporateAccounts.actions.back': 'Zurück',
  'corporateAccounts.actions.browse': 'Durchsuchen',
  'corporateAccounts.actions.cancel': 'Abbrechen',
  'corporateAccounts.actions.chooseCsv': 'CSV-Datei auswählen',
  'corporateAccounts.actions.continue': 'Weiter',
  'corporateAccounts.actions.deactivate': 'Deaktivieren',
  'corporateAccounts.actions.deactivateAccount': 'Konto deaktivieren',
  'corporateAccounts.actions.delete': 'Löschen',
  'corporateAccounts.actions.done': 'Fertig',
  'corporateAccounts.actions.download': 'Herunterladen',
  'corporateAccounts.actions.downloadLogFile': 'Protokolldatei herunterladen',
  'corporateAccounts.actions.edit': 'Bearbeiten',
  'corporateAccounts.actions.export': 'Exportieren',
  'corporateAccounts.actions.exportData': 'Daten exportieren',
  'corporateAccounts.actions.goToPage': 'Gehe zu Seite {pageName}',
  'corporateAccounts.actions.importing': 'Wird importiert...',
  'corporateAccounts.actions.migrate': '',
  'corporateAccounts.actions.next': 'Weiter',
  'corporateAccounts.actions.reactivate': 'Reaktivieren',
  'corporateAccounts.actions.reactivateAccount': 'Konto reaktivieren',
  'corporateAccounts.actions.register': 'Registrieren',
  'corporateAccounts.actions.replaceFile': 'Datei ersetzen',
  'corporateAccounts.actions.resendInvite': 'Einladung erneut senden',
  'corporateAccounts.actions.resendInvite.error': 'Einladung konnte nicht erneut gesendet werden',
  'corporateAccounts.actions.resendInvite.success': 'Einladung erneut gesendet',
  'corporateAccounts.actions.save': 'Speichern',
  'corporateAccounts.actions.sendInvite': 'Einladung senden',
  'corporateAccounts.admin.accounts': 'Konten',
  'corporateAccounts.admin.action.enterAccount': 'Konto eingeben',
  'corporateAccounts.admin.action.more': 'Mehr',
  'corporateAccounts.admin.action.moreActions': 'Weitere Aktionen',
  'corporateAccounts.admin.add.success': 'Benutzer hinzugefügt',
  'corporateAccounts.admin.corporation': 'Unternehmen',
  'corporateAccounts.admin.delete.success': 'Benutzer entfernt',
  'corporateAccounts.admin.invite.success': 'Einladungen versendet!',
  'corporateAccounts.admin.newCorporations': 'Neue Unternehmen',
  'corporateAccounts.admin.payment': 'Zahlung',
  'corporateAccounts.admin.paymentInfo': 'Angaben zur Zahlung',
  'corporateAccounts.admin.search': 'Suche nach Geschäftsname, Kurzname oder ID',
  'corporateAccounts.admin.update.success': 'Benutzer aktualisiert',
  'corporateAccounts.admins.actions.addUser': 'Benutzer hinzufügen',
  'corporateAccounts.admins.actions.deleteUser': 'Diesen Benutzer löschen?',
  'corporateAccounts.admins.actions.sendInviteMessage': 'Einladung an ausgewählte Admins senden?',
  'corporateAccounts.admins.activate.error.message':
    'Melden Sie sich an, um die Aktivierung abzuschließen',
  'corporateAccounts.admins.activate.error.title': 'Aktivierung fehlgeschlagen',
  'corporateAccounts.admins.corporateClientId': 'ID',
  'corporateAccounts.admins.corporationName': 'Unternehmensname',
  'corporateAccounts.admins.deactivate.banner.message':
    'Dieses Konto ist deaktiviert. Fahrer können nicht auf Vermögenswerte zugreifen, die diesem Unternehmen zugeordnet sind.',
  'corporateAccounts.admins.deactivate.error.body':
    'Deaktivierung des Kontos {corporateClientName} fehlgeschlagen. Bitte versuchen Sie es erneut.',
  'corporateAccounts.admins.deactivate.error.title': 'Deaktivierungsfehler',
  'corporateAccounts.admins.deactivate.modal.confirm.body':
    '<p>Möchten Sie das <strong>{corporateClientName}</strong>-Konto wirklich deaktivieren?</p><p>Bis dieses Konto wieder aktiviert wird, wird der Benutzerzugriff gesperrt und alle Fahrerprofile sind nicht mehr mit diesem Unternehmen verknüpft.</p>',
  'corporateAccounts.admins.deactivate.modal.confirm.title': 'Kontodeaktivierung',
  'corporateAccounts.admins.deactivate.success': 'Konto: {corporateClientName} wurde deaktiviert',
  'corporateAccounts.admins.delete.error.body':
    'Löschen des Kontos {corporateClientName} fehlgeschlagen.',
  'corporateAccounts.admins.delete.error.title': 'Fehler beim Löschen.',
  'corporateAccounts.admins.delete.modal.confirm.body':
    '<p>Möchten Sie das Konto <strong>{corporateClientName}</strong> wirklich dauerhaft löschen?</p><p>Diese Aktion kann nicht rückgängig gemacht werden.</p>',
  'corporateAccounts.admins.delete.modal.confirm.title': 'Löschen des Kontos',
  'corporateAccounts.admins.delete.success': 'Konto wurde gelöscht',
  'corporateAccounts.admins.editUser': 'Benutzer bearbeiten',
  'corporateAccounts.admins.email': 'E-Mail',
  'corporateAccounts.admins.firstName': 'Vorname',
  'corporateAccounts.admins.form.emailPlaceholder': 'E-Mail hinzufügen',
  'corporateAccounts.admins.form.emailRequired': 'Bitte geben Sie eine E-Mail ein',
  'corporateAccounts.admins.form.firstNamePlaceholder': 'Vorname hinzufügen',
  'corporateAccounts.admins.form.firstNameRequired': 'Bitte geben Sie einen Vornamen ein',
  'corporateAccounts.admins.form.lastNamePlaceholder': 'Nachname hinzufügen',
  'corporateAccounts.admins.form.lastNameRequired': 'Bitte geben Sie einen Nachnamen ein',
  'corporateAccounts.admins.form.phoneRequired': 'Bitte geben Sie eine Telefonnummer ein',
  'corporateAccounts.admins.form.roleRequired': 'Bitte wählen Sie eine Rolle',
  'corporateAccounts.admins.form.title.edit': 'Admin-Benutzer bearbeiten',
  'corporateAccounts.admins.form.title.new': 'Admin-Benutzer hinzufügen',
  'corporateAccounts.admins.lastName': 'Nachname',
  'corporateAccounts.admins.main.title': 'Admin-Benutzer',
  'corporateAccounts.admins.modal.downgradeAccountOwner.content':
    'Lediglich ein Upgrade eines aktiven Benutzers auf Kontoinhaber ist zulässig.',
  'corporateAccounts.admins.modal.downgradeAccountOwner.title': 'Kontoinhaber herabstufen',
  'corporateAccounts.admins.modal.upgradeBusinessManager.content':
    'Der bestehende Kontoinhaber wird zum Business Manager herabgestuft, möchten Sie fortfahren?',
  'corporateAccounts.admins.modal.upgradeBusinessManager.title':
    'Aktualisieren Sie den neuen Business Manager',
  'corporateAccounts.admins.phone': 'Telefon',
  'corporateAccounts.admins.phoneNumber': 'Telefonnummer Ansprechpartner',
  'corporateAccounts.admins.reactivate.error.body':
    'Reaktivierung des Kontos {corporateClientName} fehlgeschlagen. Bitte versuche es erneut.',
  'corporateAccounts.admins.reactivate.error.title': 'Reaktivierungsfehler',
  'corporateAccounts.admins.reactivate.modal.confirm.body':
    '<p>Möchtest du das Konto <strong>{corporateClientName}</strong> wirklich dauerhaft löschen?</p><p>Diese Aktion kann nicht rückgängig gemacht werden.</p>',
  'corporateAccounts.admins.reactivate.modal.confirm.title': 'Kontoreaktivierung',
  'corporateAccounts.admins.reactivate.success': 'Konto: {corporateClientName} wurde reaktiviert',
  'corporateAccounts.admins.role': 'Rolle',
  'corporateAccounts.admins.role.faq': 'Was bedeuten diese Berechtigungen?',
  'corporateAccounts.admins.role.faq.title': 'Details zum Profil',
  'corporateAccounts.admins.role.fleet_manager': 'Fuhrparkleiter',
  'corporateAccounts.admins.role.fleet_manager.description':
    'Zugriff auf alle Funktionen, Abodaten können jedoch nicht aktualisiert werden.',
  'corporateAccounts.admins.role.primary_fleet_manager': 'Primärer Fuhrparkleiter',
  'corporateAccounts.admins.role.primary_fleet_manager.description': 'Zugriff auf alle Funktionen',
  'corporateAccounts.admins.role.reporting': 'Berichterstattung',
  'corporateAccounts.admins.role.reporting.description': 'Für Berichte schreibgeschützt.',
  'corporateAccounts.admins.role.service_admin': 'Business Administrator',
  'corporateAccounts.admins.role.service_admin.description':
    'Zugriff auf alle Funktionen, Abodaten bzw. Zahlungsmittel des Fahrers können nicht aktualisiert werden.',
  'corporateAccounts.admins.status.activating': 'Wird aktiviert...',
  'corporateAccounts.admins.status.completeActivation':
    'Melden Sie sich an, um die Aktivierung abzuschließen',
  'corporateAccounts.admins.status.reinviteRequired': 'Erneute Einladung erforderlich',
  'corporateAccounts.auditLogs.auditDetails.description':
    '{auditLogUserAction} am {auditLogDate} von {user}',
  'corporateAccounts.auditLogs.auditDetails.id': 'ID',
  'corporateAccounts.auditLogs.auditDetails.title': 'Auditdetails',
  'corporateAccounts.auditLogs.auditDetails.workflowId': 'Workflow-ID',
  'corporateAccounts.auditLogs.emptyState.description': 'Dieser Account hat keine Protokolle',
  'corporateAccounts.auditLogs.searchInput.placeholder':
    'Suche nach Fahrern, Fahrzeugen, Benutzernamen',
  'corporateAccounts.auditLogs.table.clickForDetails': 'Klicke hier, um Details anzuzeigen',
  'corporateAccounts.auditLogs.tableColumn.userAction': 'Benutzeraktion',
  'corporateAccounts.auditLogs.tableColumn.userRole': 'Benutzerrolle',
  'corporateAccounts.auth.redirectMessage':
    'Du wirst jetzt zur Anmeldeseite weitergeleitet, um dich erneut zu authentifizieren. Vielen Dank.',
  'corporateAccounts.auth.sessionExpired': 'Deine Sitzung ist abgelaufen.',
  'corporateAccounts.billing.billingAddress': 'Rechnungsadresse',
  'corporateAccounts.billing.subscription': 'Abonnement',
  'corporateAccounts.breadcrumbs.adminUsers': 'Admin-Benutzer',
  'corporateAccounts.breadcrumbs.create': 'Hinzufügen',
  'corporateAccounts.breadcrumbs.drivers': 'Fahrer',
  'corporateAccounts.breadcrumbs.edit': 'Bearbeiten',
  'corporateAccounts.breadcrumbs.monthlyStatements': 'Monatliche Abrechnung',
  'corporateAccounts.breadcrumbs.payments': 'Zahlungsmethoden',
  'corporateAccounts.breadcrumbs.reports': 'Berichte',
  'corporateAccounts.breadcrumbs.sessions': 'Parksitzungen',
  'corporateAccounts.breadcrumbs.upload': 'Hochladen',
  'corporateAccounts.breadcrumbs.vehicles': 'Fahrzeuge',
  'corporateAccounts.button.addSingleDriver': 'Einzelnen Fahrer hinzufügen',
  'corporateAccounts.button.addSingleVehicle': 'Einzelnes Fahrzeug hinzufügen',
  'corporateAccounts.button.bulkUpload': 'Massenhochladen',
  'corporateAccounts.common.all': 'Alle',
  'corporateAccounts.common.contact': 'Kontakt',
  'corporateAccounts.common.contactSales': 'Vertrieb kontaktieren',
  'corporateAccounts.common.costCenterInfo':
    'Kostenstellen können verwendet werden, um Aufwendungen von Fahrern einer Abteilung oder einem Projekt zuzuweisen, die gefiltert werden können. Du kannst sie auch für interne Referenzen verwenden, wie z. B. die Mitarbeiter-ID des Fahrers.',
  'corporateAccounts.common.date': 'Datum',
  'corporateAccounts.common.details': 'Details',
  'corporateAccounts.common.directDebit': 'Lastschrift',
  'corporateAccounts.common.download': 'Herunterladen',
  'corporateAccounts.common.driver': 'Fahrer',
  'corporateAccounts.common.empty': 'leer',
  'corporateAccounts.common.error': 'Fehler',
  'corporateAccounts.common.error.characterCountExceeded':
    'Das Feld hat die maximale Anzahl von 75 Zeichen überschritten',
  'corporateAccounts.common.error.somethingWentWrong':
    'Etwas ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
  'corporateAccounts.common.errorCode': 'Fehlercode',
  'corporateAccounts.common.errorCode.copy':
    'Bitte kopiere den obigen Fehlercode und teile ihn dem Support-Team mit, um weitere Hilfe zu erhalten.',
  'corporateAccounts.common.frequency.weekly': 'Wöchentlich',
  'corporateAccounts.common.help': 'Hilfe',
  'corporateAccounts.common.invitationCode': 'Einladungscode',
  'corporateAccounts.common.invitationStatus': 'Einladungsstatus',
  'corporateAccounts.common.month': 'Monat',
  'corporateAccounts.common.new': 'Neu',
  'corporateAccounts.common.no': 'Nein',
  'corporateAccounts.common.password': 'Kennwort',
  'corporateAccounts.common.password.length': '8 bis 51 Zeichen lang',
  'corporateAccounts.common.password.lowercase': 'Ein Kleinbuchstabe',
  'corporateAccounts.common.password.number': 'Eine Zahl',
  'corporateAccounts.common.password.specialChar': 'Ein Sonderzeichen',
  'corporateAccounts.common.password.uppercase': 'Ein Großbuchstabe',
  'corporateAccounts.common.passwordRequired': 'Bitte gib ein Passwort ein',
  'corporateAccounts.common.pleaseTryAgain': 'Bitte versuchen Sie es erneut.',
  'corporateAccounts.common.privacyPolicy': 'Datenschutzrichtlinie',
  'corporateAccounts.common.questions': 'Fragen',
  'corporateAccounts.common.remove': 'Entfernen',
  'corporateAccounts.common.rowNumber': 'Zeilennummer',
  'corporateAccounts.common.saveSuccessful': 'Speichern erfolgreich',
  'corporateAccounts.common.search': 'Suchen',
  'corporateAccounts.common.status': 'Status',
  'corporateAccounts.common.status.active': 'Aktiv',
  'corporateAccounts.common.status.added': 'Hinzugefügt',
  'corporateAccounts.common.status.bulkAdded': 'Bulk hinzugefügt',
  'corporateAccounts.common.status.bulkDeleted': 'Bulk gelöscht',
  'corporateAccounts.common.status.closed': 'Geschlossen',
  'corporateAccounts.common.status.deactivated': 'Deaktiviert',
  'corporateAccounts.common.status.deleted': 'Gelöscht',
  'corporateAccounts.common.status.expired': 'Abgelaufen',
  'corporateAccounts.common.status.inactive': 'Inaktiv',
  'corporateAccounts.common.status.inviteExpired': 'Einladung abgelaufen',
  'corporateAccounts.common.status.inviteFailed': 'Einladung fehlgeschlagen',
  'corporateAccounts.common.status.inviteSent': 'Einladung gesendet',
  'corporateAccounts.common.status.invited': 'Eingeladen',
  'corporateAccounts.common.status.modified': 'Geändert',
  'corporateAccounts.common.status.new': 'Neu',
  'corporateAccounts.common.status.notCompleted': 'Nicht abgeschlossen',
  'corporateAccounts.common.status.notInvited': 'Nicht eingeladen',
  'corporateAccounts.common.status.onHold': 'Wartet',
  'corporateAccounts.common.status.onboarding': 'Einrichtung',
  'corporateAccounts.common.status.passive': 'Passiv',
  'corporateAccounts.common.status.pending': 'Ausstehend',
  'corporateAccounts.common.status.previous': 'Zurück',
  'corporateAccounts.common.status.prospect': 'Interessent',
  'corporateAccounts.common.status.rejected': 'Abgelehnt',
  'corporateAccounts.common.subscriptionAgreement': 'PayByPhone Business-Abonnementvereinbarung',
  'corporateAccounts.common.switchToDesktop': 'Zur Desktop-Ansicht wechseln',
  'corporateAccounts.common.switchToDesktop.description':
    'Du versuchst, das Portal über den mobilen Browser anzuzeigen. Melde dich sich über deinen Desktop-PC oder Laptop bei deinem PayByPhone Business-Konto an, um ein besseres Erlebnis zu erhalten.',
  'corporateAccounts.common.termsAndConditions': 'Allgemeine Geschäftsbedingungen',
  'corporateAccounts.common.termsAndPrivacyAgreementRequired':
    'Bitte akzeptiere die Nutzungsbedingungen und die Datenschutzerklärung',
  'corporateAccounts.common.type': 'Typ',
  'corporateAccounts.common.unexpectedError': 'Es ist ein unerwarteter Fehler aufgetreten',
  'corporateAccounts.common.update': 'Aktualisieren',
  'corporateAccounts.common.upload': 'CSV importieren',
  'corporateAccounts.common.user': 'Benutzer',
  'corporateAccounts.common.vehicle': 'Fahrzeug',
  'corporateAccounts.common.year': 'Jahr',
  'corporateAccounts.common.yes': 'Ja',
  'corporateAccounts.copyright': '2019 PayByPhone Technologies. Alle Rechte vorbehalten.',
  'corporateAccounts.copyright.allRightsReserved': 'Alle Rechte vorbehalten',
  'corporateAccounts.corporateClient.SpaCards': 'SPA-Karten',
  'corporateAccounts.corporateClient.accountOwnerStatus': 'Kontobesitzerstatus',
  'corporateAccounts.corporateClient.accountType': 'Kontotyp',
  'corporateAccounts.corporateClient.accountType.makeTestAccount':
    'Handelt es sich um ein Testkonto?',
  'corporateAccounts.corporateClient.accountType.standard': 'Standard',
  'corporateAccounts.corporateClient.accountType.test': 'Testen',
  'corporateAccounts.corporateClient.activate.error.message':
    'Deine Kontoaktivierung war nicht erfolgreich. Bitte versuche es erneut. Wenn das Problem weiterhin besteht, wende dich an unser Support-Team.',
  'corporateAccounts.corporateClient.address.error.presence': 'Bitte geben Sie eine Adresse ein',
  'corporateAccounts.corporateClient.billing.Info':
    'Hinweis: PayByPhone stellt eine Rechnung aus. Bitte stelle sicher, dass deine Rechnungsinformationen aktuell sind.',
  'corporateAccounts.corporateClient.billing.edit.error.message':
    'Beim Aktualisieren der Rechnungsadresse ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'corporateAccounts.corporateClient.billing.edit.error.title':
    'Fehler: Rechnungsadresse bearbeiten',
  'corporateAccounts.corporateClient.billing.edit.success': 'Rechnungsadresse aktualisiert.',
  'corporateAccounts.corporateClient.city': 'Ort',
  'corporateAccounts.corporateClient.city.error.presence': 'Bitte geben Sie eine Stadt ein',
  'corporateAccounts.corporateClient.clientStatus': 'Kundenstatus',
  'corporateAccounts.corporateClient.companyAddress1': 'Firmenadresse Zeile 1',
  'corporateAccounts.corporateClient.companyAddress2': 'Firmenadresse Zeile 2',
  'corporateAccounts.corporateClient.corporationNumber': 'Nummer des Unternehmens',
  'corporateAccounts.corporateClient.corporationNumber.error.presence':
    'Bitte geben Sie die Unternehmensnummer ein',
  'corporateAccounts.corporateClient.country': 'Land',
  'corporateAccounts.corporateClient.country.error.presence': 'Bitte wählen Sie ein Land aus',
  'corporateAccounts.corporateClient.country.placeholder': 'Land auswählen',
  'corporateAccounts.corporateClient.create.error':
    'Der Unternehmenskunde konnte nicht erstellt werden.',
  'corporateAccounts.corporateClient.create.success': 'Unternehmen erstellt.',
  'corporateAccounts.corporateClient.createAccount': 'Konto erstellen',
  'corporateAccounts.corporateClient.crnNo': 'CRN-Nummer',
  'corporateAccounts.corporateClient.dateCreated': 'Erstellungsdatum',
  'corporateAccounts.corporateClient.edit.error':
    'Der Unternehmenskunde konnte nicht aktualisiert werden.',
  'corporateAccounts.corporateClient.edit.success': 'Unternehmen aktualisiert.',
  'corporateAccounts.corporateClient.email': 'E-Mail-Adresse (Kontoinhaber)',
  'corporateAccounts.corporateClient.fleetSize': 'Anzahl der Fahrer',
  'corporateAccounts.corporateClient.fleetSize.error.presence':
    'Bitte wähle die Anzahl der Fahrer aus',
  'corporateAccounts.corporateClient.fleetSize.tooltip':
    'Sie können eine ungefähre Zahl angeben. Sie können später trotzdem weitere Fahrer hinzuzufügen.',
  'corporateAccounts.corporateClient.industry': 'Unternehmensbranche',
  'corporateAccounts.corporateClient.industry.placeholder': 'Branche auswählen',
  'corporateAccounts.corporateClient.industryOption.carRental': 'Autovermietung',
  'corporateAccounts.corporateClient.industryOption.cleaning': 'Reinigung',
  'corporateAccounts.corporateClient.industryOption.construction': 'Bau',
  'corporateAccounts.corporateClient.industryOption.delivery': 'Paketdienstleister',
  'corporateAccounts.corporateClient.industryOption.electricVehicles': 'Elektrofahrzeuge',
  'corporateAccounts.corporateClient.industryOption.electrical':
    'Elektrische Geräte, Haushaltsgeräte und Komponentenwartung',
  'corporateAccounts.corporateClient.industryOption.engineering': 'Technik',
  'corporateAccounts.corporateClient.industryOption.foodService': 'Gastronomie und Service',
  'corporateAccounts.corporateClient.industryOption.furniture':
    'Herstellung von Möbeln und verwandten Produkten',
  'corporateAccounts.corporateClient.industryOption.government': 'Regierung',
  'corporateAccounts.corporateClient.industryOption.healthcare': 'Gesundheitswesen und Medizin',
  'corporateAccounts.corporateClient.industryOption.higherEducation': 'Hochschulen',
  'corporateAccounts.corporateClient.industryOption.hospitals': 'Krankenhäuser',
  'corporateAccounts.corporateClient.industryOption.hotelChain': 'Hotel/Kette',
  'corporateAccounts.corporateClient.industryOption.insurance': 'Versicherung',
  'corporateAccounts.corporateClient.industryOption.landscape':
    'Landschaftsgestaltung und Architektur',
  'corporateAccounts.corporateClient.industryOption.manufacturing':
    'Fertigung, Transport und Logistik',
  'corporateAccounts.corporateClient.industryOption.mobileHealth': 'Mobiler Gesundheitsdienst',
  'corporateAccounts.corporateClient.industryOption.mobileSalesTeam': 'Mobile Sales-Team',
  'corporateAccounts.corporateClient.industryOption.municipal': 'Kommunen',
  'corporateAccounts.corporateClient.industryOption.officeParking': 'Büroparkplätze',
  'corporateAccounts.corporateClient.industryOption.other': 'Sonstiges',
  'corporateAccounts.corporateClient.industryOption.passengerTerminal': 'Passagierterminal',
  'corporateAccounts.corporateClient.industryOption.printing':
    'Druck und ähnliche Support-Aktivitäten',
  'corporateAccounts.corporateClient.industryOption.realEstate': 'Immobilien',
  'corporateAccounts.corporateClient.industryOption.retail': 'Einzelhandel',
  'corporateAccounts.corporateClient.industryOption.specialtyTrade': 'Fachhandelsunternehmen',
  'corporateAccounts.corporateClient.industryOption.sport': 'Sport und Freizeit',
  'corporateAccounts.corporateClient.industryOption.telecommunications': 'Telekommunikation',
  'corporateAccounts.corporateClient.industryOption.tourism': 'Tourismus',
  'corporateAccounts.corporateClient.industryOption.utilities': 'Versorgungsunternehmen',
  'corporateAccounts.corporateClient.name': 'Unternehmensname',
  'corporateAccounts.corporateClient.name.error.presence': 'Gib den Unternehmensnamen ein',
  'corporateAccounts.corporateClient.officeNumber': 'Büro-/Etagen-Nr.',
  'corporateAccounts.corporateClient.owner.firstName.error.presence':
    'Bitte geben Sie einen Vornamen ein',
  'corporateAccounts.corporateClient.parkingPaymentMethod': 'Zahlungsweise für das Parken',
  'corporateAccounts.corporateClient.payment.accountOwnerEmail': 'E-Mail des Kontoinhabers',
  'corporateAccounts.corporateClient.payment.automaticPayment':
    'Automatische wiederkehrende Zahlung',
  'corporateAccounts.corporateClient.payment.cardExpiry': 'Ablauf der Karte',
  'corporateAccounts.corporateClient.payment.error.valueType': 'Dieser Wert muss eine Zahl sein.',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.invalid':
    'Bitte geben Sie einen gültigen Monat ein',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.presence':
    'Bitte geben Sie einen Monat (MM) ein.',
  'corporateAccounts.corporateClient.payment.expiryYear.error':
    'Bitte geben Sie ein gültiges Ablaufdatum ein.',
  'corporateAccounts.corporateClient.payment.expiryYear.error.presence':
    'Bitte geben Sie ein Ablaufjahr ein (JJJJ).',
  'corporateAccounts.corporateClient.payment.firstSixdigits': 'Die ersten sechs Ziffern',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.length':
    'Bitte genau 6 Stellen eingeben',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.presence':
    'Bitte geben Sie die ersten sechs Ziffern der Kreditkarte ein.',
  'corporateAccounts.corporateClient.payment.invoiceSent':
    'Die Rechnungen werden an folgende E-Mail-Adresse(n) geschickt:',
  'corporateAccounts.corporateClient.payment.invoicedManually': 'Manuell fakturiert',
  'corporateAccounts.corporateClient.payment.lastFourDigits': 'Die letzten vier Ziffern',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.length':
    'Bitte genau 4 Stellen eingeben',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.presence':
    'Bitte geben Sie die letzten vier Ziffern der Kreditkarte ein.',
  'corporateAccounts.corporateClient.payment.noInviteSent':
    'Nach Erstellen des Unternehmens wird keine Einladung mehr verschickt',
  'corporateAccounts.corporateClient.payment.paymentInfo': 'Parken Informationen zur Zahlung',
  'corporateAccounts.corporateClient.payment.subscriptionCommitted':
    'Geschäftskunde hat ein Abonnement gebucht? (J/N)',
  'corporateAccounts.corporateClient.postalCode': 'Postleitzahl',
  'corporateAccounts.corporateClient.postalCode.error.presence':
    'Bitte geben Sie eine Postleitzahl ein',
  'corporateAccounts.corporateClient.postalCode.error.validity': 'Postleitzahl ist ungültig.',
  'corporateAccounts.corporateClient.preferredLanguage': 'Sprachpräferenz Mitarbeiter',
  'corporateAccounts.corporateClient.preferredLanguage.error.presence':
    'Bitte wählen Sie eine Sprache',
  'corporateAccounts.corporateClient.preferredLanguage.placeholder': 'Sprache auswählen',
  'corporateAccounts.corporateClient.region': 'Region',
  'corporateAccounts.corporateClient.region.error.presence': 'Bitte geben Sie eine Region ein',
  'corporateAccounts.corporateClient.region.placeholder': 'Region auswählen',
  'corporateAccounts.corporateClient.register.addressTitle': 'Registrierte Rechnungsanschrift',
  'corporateAccounts.corporateClient.register.duplicateNameError.message':
    'Versuche es erneut mit einem anderen Namen.',
  'corporateAccounts.corporateClient.register.duplicateNameError.title':
    'Fehler: Ein Unternehmen mit diesem Namen ist bereits vorhanden',
  'corporateAccounts.corporateClient.register.internalError.message':
    'Bei der Registrierung deines Unternehmens ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'corporateAccounts.corporateClient.register.internalError.title':
    'Fehler: Unternehmen registrieren',
  'corporateAccounts.corporateClient.register.registrationInfo':
    'Deine registrierte Adresse wird auf den Rechnungen angezeigt. Du kannst diese Informationen auf der Seite „Abrechnung und Abonnement“ jederzeit aktualisieren.',
  'corporateAccounts.corporateClient.registrationType': 'Registrierungstyp',
  'corporateAccounts.corporateClient.registrationType.enterprise': 'Unternehmen',
  'corporateAccounts.corporateClient.registrationType.selfRegistered': 'Selbstregistriert',
  'corporateAccounts.corporateClient.restrictionType': 'Einschränkungstyp',
  'corporateAccounts.corporateClient.restrictionType.restricted': 'Eingeschränkt',
  'corporateAccounts.corporateClient.restrictionType.unrestricted': 'Uneingeschränkt',
  'corporateAccounts.corporateClient.salesforceId': 'Rechnungsnummer',
  'corporateAccounts.corporateClient.salesforceId.error.alphanumeric':
    'Die Salesforce-ID darf nur Buchstaben und Zahlen enthalten',
  'corporateAccounts.corporateClient.salesforceId.error.invalid': 'Keine gültige Salesforce-ID',
  'corporateAccounts.corporateClient.salesforceId.error.length':
    'Die Salesforce darf nur 15 oder 18 Zeichen lang sein',
  'corporateAccounts.corporateClient.salesforceId.error.presence':
    'Geben Sie die Rechnungsnummer ein',
  'corporateAccounts.corporateClient.salesforceId.infoMessage':
    'Salesforce-IDs werden für die Abrechnung verwendet, Kunden mit falscher ID erhalten keine Rechnung. Diese ID findest du unter „Safe Case ID“ auf der Seite „Account“ in Salesforce und sie sollte kopiert und in dieses Feld eingefügt werden.',
  'corporateAccounts.corporateClient.siretNo': 'SIRET-Nr.',
  'corporateAccounts.corporateClient.stateProvince': 'Bundesland/Provinz',
  'corporateAccounts.corporateClient.streetAddress': 'Straße und Hausnummer',
  'corporateAccounts.corporateClient.taxNumber': 'Steuernummer',
  'corporateAccounts.corporateClient.taxNumber.error.characterCountExceeded':
    'Höchstens 14 Zeichen (nur Buchstaben und Zahlen).',
  'corporateAccounts.corporateClient.taxNumber.error.notAlphanumeric':
    'Bitte geben Sie nur Buchstaben und Zahlen ein.',
  'corporateAccounts.corporateClient.taxNumber.error.presence': 'Bitte Steuernummer eingeben',
  'corporateAccounts.corporateClient.taxNumber.error_presence': 'Bitte {taxNumber} eingeben',
  'corporateAccounts.corporateClient.version': 'Version',
  'corporateAccounts.corporations.register.subtitle':
    'Wir benötigen nur ein paar Details zu Ihrem Unternehmen',
  'corporateAccounts.corporations.register.title': 'Willkommen bei PayByPhone Business!',
  'corporateAccounts.corporations.title': 'Meine Konten',
  'corporateAccounts.countries.ad': 'Andorra (AD)',
  'corporateAccounts.countries.al': 'Albanien (AL)',
  'corporateAccounts.countries.am': 'Armenien (AM)',
  'corporateAccounts.countries.at': 'Österreich',
  'corporateAccounts.countries.au': 'Australien (AU)',
  'corporateAccounts.countries.ba': 'Bosnien und Herzegowina (BA)',
  'corporateAccounts.countries.be': 'Belgien (BE)',
  'corporateAccounts.countries.bg': 'Bulgarien (BG)',
  'corporateAccounts.countries.by': 'Weißrussland (BY)',
  'corporateAccounts.countries.ca': 'Kanada (CA)',
  'corporateAccounts.countries.ch': 'Schweiz (CH)',
  'corporateAccounts.countries.cy': 'Zypern (CY)',
  'corporateAccounts.countries.cz': 'Tschechische Republik',
  'corporateAccounts.countries.de': 'Deutschland (DE)',
  'corporateAccounts.countries.dk': 'Dänemark (DK)',
  'corporateAccounts.countries.ee': 'Estland (EE)',
  'corporateAccounts.countries.es': 'Spanien',
  'corporateAccounts.countries.fi': 'Finnland (FI)',
  'corporateAccounts.countries.fr': 'Frankreich (FR)',
  'corporateAccounts.countries.gb': 'Vereinigtes Königreich (GB)',
  'corporateAccounts.countries.gr': 'Griechenland (GR)',
  'corporateAccounts.countries.hr': 'Kroatien (HR)',
  'corporateAccounts.countries.hu': 'Ungarn (HU)',
  'corporateAccounts.countries.i': 'Slowenien (I)',
  'corporateAccounts.countries.ie': 'Irland (IE)',
  'corporateAccounts.countries.is': 'Island (IS)',
  'corporateAccounts.countries.it': 'Italien',
  'corporateAccounts.countries.li': 'Liechtenstein',
  'corporateAccounts.countries.lt': 'Litauen (LT)',
  'corporateAccounts.countries.lu': 'Luxemburg (LU)',
  'corporateAccounts.countries.lv': 'Lettland (LV)',
  'corporateAccounts.countries.mc': 'Monaco (MC)',
  'corporateAccounts.countries.md': 'Moldawien (MD)',
  'corporateAccounts.countries.me': 'Montenegro (ME)',
  'corporateAccounts.countries.mk': 'Nordmazedonien (MK)',
  'corporateAccounts.countries.mt': 'Malta (MT)',
  'corporateAccounts.countries.nl': 'Niederlande (NL)',
  'corporateAccounts.countries.no': 'Norwegen (NO)',
  'corporateAccounts.countries.pl': 'Polen (PL)',
  'corporateAccounts.countries.pt': 'Portugal (PT)',
  'corporateAccounts.countries.ro': 'Rumänien (RO)',
  'corporateAccounts.countries.rs': 'Serbien (RS)',
  'corporateAccounts.countries.ru': 'Russland (RU)',
  'corporateAccounts.countries.se': 'Schweden (SE)',
  'corporateAccounts.countries.sk': 'Slowakei (SK)',
  'corporateAccounts.countries.sm': 'San Marino (SM)',
  'corporateAccounts.countries.tr': 'Türkei (TR)',
  'corporateAccounts.countries.ua': 'Ukraine (UA)',
  'corporateAccounts.countries.us': 'Vereinigte Staaten (USA)',
  'corporateAccounts.countries.va': 'Vatikan (VA)',
  'corporateAccounts.creditAccount.authorizedDriversBlurb':
    'Bei autorisierten Fahrern ist die geschäftliche Zahlungsmethode in der PayByPhone App automatisch hinterlegt.',
  'corporateAccounts.creditAccount.details.accountNumber': 'Kontonummer',
  'corporateAccounts.creditAccount.details.approvedCreditLimit': 'Genehmigtes Kreditlimit',
  'corporateAccounts.creditAccount.details.availableCredit': 'Verfügbares Guthaben',
  'corporateAccounts.creditAccount.details.creditAccountDetails': 'Angaben zum Guthabenkonto',
  'corporateAccounts.creditAccount.details.creditAccountId': '',
  'corporateAccounts.creditAccount.details.currentSpend': 'Aktuelle Ausgaben',
  'corporateAccounts.creditAccount.details.expiryDate':
    'Karte endend auf {cardNumber} läuft ab am {expiryDate}',
  'corporateAccounts.creditAccount.details.invoicingFrequency': 'Abrechnungshäufigkeit',
  'corporateAccounts.creditAccount.details.lastUpdated': 'Zuletzt aktualisiert',
  'corporateAccounts.creditAccount.details.nextPaymentDate': 'Nächster Zahlungstermin',
  'corporateAccounts.creditAccount.details.overLimitPaymentMethod':
    'Zahlungsart für Transaktionen über dem Kreditlimit',
  'corporateAccounts.creditAccount.details.paymentAmountDue': 'Fälliger Zahlungsbetrag',
  'corporateAccounts.creditAccount.details.paymentDetails': 'Zahlungsangaben',
  'corporateAccounts.creditAccount.details.paymentMethod': 'Zahlungsart',
  'corporateAccounts.creditAccount.details.toolTip':
    'Gesamte Park- und Abonnementgebühren für diesen Abrechnungszyklus',
  'corporateAccounts.creditAccount.internalError': '',
  'corporateAccounts.creditAccount.migrationStatus': '',
  'corporateAccounts.creditAccount.migrationStatus.notStarted': '',
  'corporateAccounts.creditAccount.migrationStatus.started': '',
  'corporateAccounts.creditAccount.pageTitle': 'Guthabenkonto',
  'corporateAccounts.creditAccount.rejectedBanner.cta': '',
  'corporateAccounts.creditAccount.rejectedBanner.description': '',
  'corporateAccounts.creditAccount.rejectedBanner.title': '',
  'corporateAccounts.creditAccount.seeFaq': 'Siehe FAQ',
  'corporateAccounts.creditAccount.setUpButton': 'Guthabenkonto einrichten',
  'corporateAccounts.creditAccount.status': '',
  'corporateAccounts.creditAccount.stepCard1.subtitle':
    'Wir bitten Sie, die Firmenanschrift und die Registrierungsnummer zu überprüfen.',
  'corporateAccounts.creditAccount.stepCard1.title': '1. Firmenangaben bestätigen',
  'corporateAccounts.creditAccount.stepCard2.subtitle':
    'Bitte schätzen Sie Ihre monatlichen Parkkosten, damit wir Ihr Kreditlimit ermitteln können.',
  'corporateAccounts.creditAccount.stepCard2.title': '2. Vorabgenehmigung einholen',
  'corporateAccounts.creditAccount.stepCard3.subtitle':
    'Fahrzeugrisieren Sie die bequeme Zahlung Ihrer Rechnungen im Lastschriftverfahren.',
  'corporateAccounts.creditAccount.stepCard3.title': '3. Lastschrift einrichten',
  'corporateAccounts.creditAccount.subtitle':
    'Eine spezielle Kreditlinie für alle Ihre Parkkosten.',
  'corporateAccounts.creditAccount.title':
    'Mit PayByPhone Business jetzt parken und später bezahlen.',
  'corporateAccounts.creditAccount.type': '',
  'corporateAccounts.creditAccount.type.creditAccount': '',
  'corporateAccounts.creditAccount.type.creditBuilder': '',
  'corporateAccounts.creditAccount.type.hybrid': '',
  'corporateAccounts.creditAccountMigration.error': '',
  'corporateAccounts.creditAccountMigration.error.countryNotSupported': '',
  'corporateAccounts.creditAccountMigration.error.notFound': '',
  'corporateAccounts.creditAccountMigration.success': '',
  'corporateAccounts.daysOfWeek.0': 'Sonntag',
  'corporateAccounts.daysOfWeek.1': 'Montag',
  'corporateAccounts.daysOfWeek.2': 'Dienstag',
  'corporateAccounts.daysOfWeek.3': 'Mittwoch',
  'corporateAccounts.daysOfWeek.4': 'Donnerstag',
  'corporateAccounts.daysOfWeek.5': 'Freitag',
  'corporateAccounts.daysOfWeek.6': 'Samstag',
  'corporateAccounts.driver.activate.success': 'Aktivierungen gesendet',
  'corporateAccounts.driver.activate.warning':
    'Für einige Fahrer konnte die Aktivierung nicht durchgeführt werden, da sie über kein PayByPhone-Konto verfügen. Versuchen Sie es erneut, wenn die Konten eingerichtet wurden.',
  'corporateAccounts.driver.add.error': 'Wir konnten deinen Fahrer nicht hinzufügen.',
  'corporateAccounts.driver.bulkDelete.success': '{numOfDeletedDrivers} Fahrer wurden gelöscht',
  'corporateAccounts.driver.delete.error':
    'Die Löschanforderung konnte nicht abgeschlossen werden.',
  'corporateAccounts.driver.delete.success': 'Fahrer entfernt',
  'corporateAccounts.driver.generic.error':
    'Bei der Aktualisierung des ausgewählten Fahrers ist ein Problem aufgetreten.',
  'corporateAccounts.driver.get.error': 'Beim Abrufen deiner Fahrer ist ein Fehler aufgetreten.',
  'corporateAccounts.driver.invite.error':
    'Mindestens eine Einladung war nicht erfolgreich. Versuchen Sie es erneut.',
  'corporateAccounts.driver.invite.success': 'Einladungen gesendet',
  'corporateAccounts.driver.limitExceeded.error':
    'Du hast dein Fahrerlimit überschritten. Wenn du mehr darüber erfahren möchtest, wie du dein Limit erhöhen kannst, wende dich bitte an den Vertrieb.',
  'corporateAccounts.driver.profileExists.error':
    'Ein Fahrerprofil mit dieser Telefonnummer ist bereits vorhanden.',
  'corporateAccounts.driver.update.error': 'Wir konnten deinen Fahrer nicht bearbeiten.',
  'corporateAccounts.driver.update.profileExistsError':
    'Aktualisierung fehlgeschlagen - Fahrerprofil mit dieser Telefonnummer ist bereits vorhanden.',
  'corporateAccounts.driver.update.success': 'Fahrer aktualisiert',
  'corporateAccounts.drivers.actions': 'Aktionen',
  'corporateAccounts.drivers.actions.activate': 'Aktivieren',
  'corporateAccounts.drivers.actions.addParker': 'Fahrer hinzufügen',
  'corporateAccounts.drivers.actions.deleteParkerMessage': 'Diesen Fahrer löschen?',
  'corporateAccounts.drivers.actions.reinvite': 'Erneut einladen',
  'corporateAccounts.drivers.activationModal.body1':
    'Für die Fahrer kann die Aktivierung erst durchgeführt werden, wenn sie ein PayByPhone-Konto eingerichtet haben.',
  'corporateAccounts.drivers.activationModal.body2':
    'Sie können dafür die PayByPhone-App herunterladen und mithilfe der Telefonnummer in Ihrer Fahrerliste ein Konto registrieren.',
  'corporateAccounts.drivers.bulkDelete.modal.content':
    'Sind Sie sicher, dass Sie die ausgewählten Fahrer löschen und deren Zugriff auf Geschäftszahlungsmethoden entfernen möchten?',
  'corporateAccounts.drivers.bulkDelete.modal.title': 'Löschen von Fahrern',
  'corporateAccounts.drivers.bulkResendInvite.sucess': 'Einladungen gesendet',
  'corporateAccounts.drivers.bulkResendInvite.warning':
    'Einige Fahrereinladungen wurden nicht gesendet, weil sie bereits über ein aktives Konto verfügen',
  'corporateAccounts.drivers.bulkResendInviteModal.description':
    'Der Einladungslink läuft nach 1 Woche ab. Alle aktiven Fahrer erhalten keine Einladung.',
  'corporateAccounts.drivers.bulkResendInviteModal.title': 'Einladung erneut senden?',
  'corporateAccounts.drivers.costCenter': 'Kostenstelle',
  'corporateAccounts.drivers.costCenterInfo':
    'Kostenstellen können verwendet werden, um Aufwendungen von Fahrern einer Abteilung oder einem Projekt zuzuweisen, die gefiltert werden können. Du kannst sie auch für interne Referenzen verwenden, wie z. B. die Mitarbeiter-ID des Fahrers.',
  'corporateAccounts.drivers.csvDownload.costCenter': 'Kostenstelle',
  'corporateAccounts.drivers.csvDownload.email': 'E-Mail (erforderlich)',
  'corporateAccounts.drivers.csvDownload.phoneCountryCode':
    'Ländercode [1 / 44 / 33 / usw.] (erforderlich)',
  'corporateAccounts.drivers.csvDownload.phoneNumber': 'Telefonnummer (erforderlich)',
  'corporateAccounts.drivers.deleteModal.content':
    'Möchtest du diesen Fahrer wirklich löschen? Der Fahrer hat bei einer Löschung keinen Zugriff auf geschäftliche Zahlungsarten.',
  'corporateAccounts.drivers.deleteModal.title': 'Fahrer löschen?',
  'corporateAccounts.drivers.department': 'Abteilung',
  'corporateAccounts.drivers.email': 'E-Mail',
  'corporateAccounts.drivers.employeeId': 'Mitarbeiter-ID',
  'corporateAccounts.drivers.emptyState.description':
    'Nur autorisierte Fahrer können mit deinen Geschäftszahlungsmethoden für das Parken bezahlen. Du kannst Fahrer per SMS oder E-Mail benachrichtigen, um die PayByPhone-App zu installieren. Fahrer können ihr bestehendes Konto verwenden',
  'corporateAccounts.drivers.emptyState.importDriversBtn': 'Fahrer importieren',
  'corporateAccounts.drivers.emptyState.videoLink': 'So lädt man Fahrer ein',
  'corporateAccounts.drivers.export.error.modal.description':
    'Fahrerliste konnte nicht exportiert werden. Bitte versuchen Sie es erneut.',
  'corporateAccounts.drivers.export.error.modal.title': 'Fehler beim Exportieren der Fahrerliste',
  'corporateAccounts.drivers.export.fileName': 'Fahrerliste',
  'corporateAccounts.drivers.export.modal.description':
    'Exportierte Daten werden basierend auf den Filtern generiert, die derzeit auf Ihre Fahrerliste angewendet werden.',
  'corporateAccounts.drivers.export.modal.title': 'Export der Fahrerliste',
  'corporateAccounts.drivers.export.success.notification.description':
    'Ihre Fahrerliste wurde exportiert.',
  'corporateAccounts.drivers.export.success.notification.title': 'Fahrerliste exportiert',
  'corporateAccounts.drivers.firstName': 'Vorname',
  'corporateAccounts.drivers.form.add': 'Fahrer hinzufügen',
  'corporateAccounts.drivers.form.costCenterMaxLength':
    'Die Kostenstelle darf eine Länge von 15 Zeichen nicht überschreiten.',
  'corporateAccounts.drivers.form.emailRequired': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  'corporateAccounts.drivers.form.emailValidation':
    'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  'corporateAccounts.drivers.form.employeeIdValidation':
    'Mitarbeiter-ID darf nicht länger als 50 Zeichen sein',
  'corporateAccounts.drivers.form.firstNameValidation':
    'Ihr Vorname darf 50 Zeichen nicht überschreiten',
  'corporateAccounts.drivers.form.invitationOptions': 'Fahrer einladen über',
  'corporateAccounts.drivers.form.inviteOptionsError': 'Wähle mindestens eine Option aus',
  'corporateAccounts.drivers.form.jobTitleValidation':
    'Die Stellenbezeichnung darf eine Länge von 50 Zeichen nicht überschreiten',
  'corporateAccounts.drivers.form.lastNameValidation':
    'Ihr Nachname darf 50 Zeichen nicht überschreiten',
  'corporateAccounts.drivers.form.phoneRequired': 'Bitte geben Sie eine Telefonnummer ein',
  'corporateAccounts.drivers.form.phoneValidation':
    'Bitte geben Sie eine gültige Telefonnummer ein',
  'corporateAccounts.drivers.form.selectInvitationMethod': 'Wähle eine Einladungsmethode aus',
  'corporateAccounts.drivers.form.sms': 'SMS',
  'corporateAccounts.drivers.form.title.edit': 'Fahrer bearbeiten',
  'corporateAccounts.drivers.form.title.new': 'Neuen Fahrer hinzufügen',
  'corporateAccounts.drivers.form.update': 'Fahrer aktualisieren',
  'corporateAccounts.drivers.groupId': 'Gruppen-ID',
  'corporateAccounts.drivers.import.error':
    'Einige oder alle Fahrereinträge sind ungültig. Lade die Protokolldatei herunter, um weitere Details zu erhalten. Nach der Korrektur erneut versuchen.',
  'corporateAccounts.drivers.import.error.EmailRequired': 'Fehlender Eintrag (E-Mail)',
  'corporateAccounts.drivers.import.error.UnknownParsingError':
    'Beim Parsen der Datei ist ein Fehler aufgetreten.',
  'corporateAccounts.drivers.import.error.costCenterMaxLengthExceeded':
    'Maximale Dauer überschritten (Kostenstelle)',
  'corporateAccounts.drivers.import.error.csvNoRecord':
    'Es sind keine gültigen Fahrer für den Import vorhanden.',
  'corporateAccounts.drivers.import.error.duplicateEntries': 'Fahrer duplizieren',
  'corporateAccounts.drivers.import.error.failed': 'Fahrer konnten nicht importiert werden.',
  'corporateAccounts.drivers.import.error.fileName': 'Protokolldatei',
  'corporateAccounts.drivers.import.error.firstNameMaxLengthExceeded':
    'Maximale Länge überschritten (Vorname)',
  'corporateAccounts.drivers.import.error.firstNameRequired': 'Fehlender Eintrag (Vorname)',
  'corporateAccounts.drivers.import.error.invalidCostCenter': 'Falsches Format (Kostenstelle)',
  'corporateAccounts.drivers.import.error.invalidEmail': 'Falsches Format (E-Mail)',
  'corporateAccounts.drivers.import.error.invalidEntries': 'Ungültige Fahrereinträge',
  'corporateAccounts.drivers.import.error.invalidNumbers': 'Falsches Format (Telefonnummer)',
  'corporateAccounts.drivers.import.error.lastNameMaxLengthExceeded':
    'Maximale Länge überschritten (Nachname)',
  'corporateAccounts.drivers.import.error.lastNameRequired': 'Fehlender Eintrag (Nachname)',
  'corporateAccounts.drivers.import.error.phoneNumberRequired': 'Fehlender Eintrag (Telefonnummer)',
  'corporateAccounts.drivers.import.error.uploadFile':
    'Beim Hochladen der Datei ist ein Problem aufgetreten. Stelle sicher, dass du eine gültige CSV-Datei hochlädst, und versuche es erneut.',
  'corporateAccounts.drivers.import.failed': 'Import fehlgeschlagen',
  'corporateAccounts.drivers.import.fileName': 'PayByPhone Business Vorlage für Fahrerimport',
  'corporateAccounts.drivers.import.header.costCenter': 'Kostenstelle (Optional)',
  'corporateAccounts.drivers.import.header.countryCode': 'Telefonnummer Landesvorwahl',
  'corporateAccounts.drivers.import.header.email': 'E-Mail',
  'corporateAccounts.drivers.import.header.phoneNumber': 'Telefonnummer',
  'corporateAccounts.drivers.import.inProgress':
    'Import mit {total} Fahrern ({driversAdded} neu, {driversEdited} bearbeitet) wird durchgeführt',
  'corporateAccounts.drivers.import.spaModal.title': 'Wichtig, bitte lesen!',
  'corporateAccounts.drivers.import.success':
    '{total} Fahrer erfolgreich importiert. ({driversAdded} neu, {driversEdited} bearbeitet)',
  'corporateAccounts.drivers.import.warning':
    '{total} Fahrer erfolgreich importiert! ({driversAdded} neue, {driversEdited} bearbeitete), {driversFailed} Fahrer konnten nicht importiert werden.',
  'corporateAccounts.drivers.importModal.editDrivers': 'Bearbeiten vorhandener Fahrer',
  'corporateAccounts.drivers.importModal.editDrivers.1':
    'Lade diese CSV mit allen Fahrerdaten herunter.',
  'corporateAccounts.drivers.importModal.editDrivers.2': 'Bearbeite vorhandene Fahrerdaten.',
  'corporateAccounts.drivers.importModal.editDrivers.3': 'Lade die aktualisierte CSV-Datei hoch',
  'corporateAccounts.drivers.importModal.newDrivers': 'Importieren neuer Fahrer',
  'corporateAccounts.drivers.importModal.newDrivers.1':
    'Füge in jeder Zeile neue Fahrerdaten hinzu.',
  'corporateAccounts.drivers.importModal.title': 'Fahrer per CSV importieren',
  'corporateAccounts.drivers.infograph.card.description':
    'Bitte die Fahrer, die PayByPhone-App herunterzuladen und ein Konto einzurichten. Inaktive Fahrer müssen erst aktiviert werden, bevor sie Ihre Zahlungsmethoden sehen können.',
  'corporateAccounts.drivers.infograph.card.title': 'Deine Fahrer werden hier aufgeführt',
  'corporateAccounts.drivers.infograph.cards.0.body':
    'Bitten Sie die Fahrer, die PayByPhone-App herunterzuladen und ein Konto einzurichten.',
  'corporateAccounts.drivers.infograph.cards.0.title': 'Ihre Fahrer benachrichtigen',
  'corporateAccounts.drivers.infograph.cards.1.title': 'Fahrerliste hochladen',
  'corporateAccounts.drivers.infograph.cards.2.body':
    'Inaktive Fahrer müssen erst aktiviert werden, bevor sie Ihre Zahlungsmethoden sehen können.',
  'corporateAccounts.drivers.infograph.cards.2.title': 'Fahrer aktivieren',
  'corporateAccounts.drivers.infograph.subcontent':
    'Fahrer mit dem Status „Inaktiv“ müssen über die Schaltfläche „Aktivieren“ aktiviert werden. Die Telefonnummer des PayByPhone-Kontos des Fahrers muss mit der Telefonnummer auf dieser Liste übereinstimmen.',
  'corporateAccounts.drivers.inviteDriverToolTip':
    'Eine Benachrichtigung mit dem Einladungslink wird an einen Fahrer gesendet. Der Einladungslink ist 7 Tage lang aktiv. Du siehst eine Änderung des Fahrerstatus in „Active“, sobald sie die Einladung annehmen. Wenn der Einladungslink abläuft, kannst du ihn über das Dropdown-Menü „More“ neben einem Fahrer erneut senden.',
  'corporateAccounts.drivers.jobTitle': 'Stellenbezeichnung',
  'corporateAccounts.drivers.lastName': 'Nachname',
  'corporateAccounts.drivers.main.title': 'Fahrer',
  'corporateAccounts.drivers.mobilePhoneNumber': 'Handynummer',
  'corporateAccounts.drivers.resendInviteModal.description': 'Der Link läuft in 1 Woche ab',
  'corporateAccounts.drivers.restrictedAction.add.content':
    'Diese Funktion ist vorübergehend eingeschränkt. Ihr PaybyPhone-Kundenbetreuer kann Ihnen jedoch bei diesem Vorgang behilflich sein, sodass Ihre Mitarbeiter/innen sofort mit dem Parken beginnen können!',
  'corporateAccounts.drivers.restrictedAction.add.title': 'Möchten Sie neue Fahrer hinzufügen?',
  'corporateAccounts.drivers.restrictedAction.edit.content':
    'Diese Funktion ist vorübergehend eingeschränkt, Ihr PaybyPhone-Kundenbetreuer kann Ihnen hierbei jedoch behilflich sein.',
  'corporateAccounts.drivers.restrictedAction.edit.title': 'Möchten Sie einen Fahrer bearbeiten?',
  'corporateAccounts.drivers.restrictedAction.upload.title': 'Möchten Sie Fahrer hochladen?',
  'corporateAccounts.drivers.search.placeholder': 'Treiber suchen',
  'corporateAccounts.drivers.workPhoneNumber': 'Telefonnummer Arbeit',
  'corporateAccounts.errors.fileUpload':
    'Beim Hochladen ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'corporateAccounts.errors.unauthorized.content':
    'Sie sind keinem Unternehmen zugeordnet, bitte wenden Sie sich an den Support.',
  'corporateAccounts.errors.unauthorized.title': 'Nicht autorisierter Zugriff',
  'corporateAccounts.feedbackModal.button': 'Feedback senden',
  'corporateAccounts.feedbackModal.feedback.description':
    'Haben Sie Feedback oder möchten Sie eine Funktionsanfrage senden?',
  'corporateAccounts.feedbackModal.feedback.placeholder': 'Geben Sie hier Ihr Feedback ein',
  'corporateAccounts.feedbackModal.feedback.subDescription':
    'Wir würden uns freuen, von Ihnen zu hören, und Ihr Feedback wird von unserem Produktentwicklungsteam überprüft.',
  'corporateAccounts.feedbackModal.feedback.success': 'Vielen Dank für Ihr Feedback.',
  'corporateAccounts.feedbackModal.support.button': 'Senden Sie eine Support-Anfrage',
  'corporateAccounts.feedbackModal.support.description':
    'Benötigen Sie technischen Support oder möchten Sie einen Fehler melden?',
  'corporateAccounts.feedbackModal.title': 'Feedback geben',
  'corporateAccounts.fileUpload.loading': 'Dies kann einige Sekunden dauern ...',
  'corporateAccounts.globalNotification.maintenanceMessage':
    'Die Wartung ist geplant für {weekday}, {monthDay} von {startTime} bis {endTime}. Während dieses Zeitraums ist der Zugriff auf PayByPhone Business nicht verfügbar.',
  'corporateAccounts.globalNotification.maintenancePage.restoreTime':
    'Die Website wird um {maintenanceEndHour} wiederhergestellt. Vielen Dank für deine Geduld.',
  'corporateAccounts.globalNotification.maintenancePage.underGoingScheduledMaintenance':
    'Unsere Website wird derzeit planmäßig gewartet.',
  'corporateAccounts.globalNotification.maintenancePage.underScheduleMaintenance':
    'Website wird planmäßig gewartet...',
  'corporateAccounts.homePage': 'Kehre zur Startseite zurück',
  'corporateAccounts.import.downloadCsv': 'Lade diese CSV-Vorlage herunter',
  'corporateAccounts.import.downloadCsvTemplate': 'Lade diese CSV-Vorlage herunter',
  'corporateAccounts.import.driver.success':
    'Das Importieren von Fahrerdaten kann einige Stunden dauern.',
  'corporateAccounts.import.emailNotify.success':
    'Nach Abschluss des Vorgangs erhalten Sie eine E-Mail-Benachrichtigung.',
  'corporateAccounts.import.importData': 'Daten importieren',
  'corporateAccounts.import.maxFileSize.error':
    'Derzeit werden nur Dateien mit einer Größe von maximal 1 MB akzeptiert.',
  'corporateAccounts.import.singleFile.error':
    'Derzeit ist nur 1 Datei pro Hochladevorgang zulässig.',
  'corporateAccounts.import.step.1.title': 'Schritt 1:',
  'corporateAccounts.import.step.2.title': 'Schritt 2:',
  'corporateAccounts.import.step.3.title': 'Schritt 3:',
  'corporateAccounts.import.step.5.content': 'Daten aus der ausgewählten Datei auswählen.',
  'corporateAccounts.import.title.success': 'Hochgeladene Datei',
  'corporateAccounts.import.vehicle.success':
    'Das Importieren von Fahrzeugdaten kann einige Stunden dauern',
  'corporateAccounts.infograph.drivers.cards.0.body':
    'Nachdem Sie eine Zahlungsmethode und Fahrzeuge hinzugefügt haben, ist der Zeitpunkt gekommen, die Daten Ihres Fahrers in das Portal einzugeben.',
  'corporateAccounts.infograph.drivers.cards.0.title': 'EINEN FAHRER HINZUFÜGEN',
  'corporateAccounts.infograph.drivers.cards.1.body':
    'Sie können Ihren Fahrern eine automatische Einladung senden. Einladungsprozess anzeigen.',
  'corporateAccounts.infograph.drivers.cards.1.title': 'EINLADUNG SENDEN',
  'corporateAccounts.infograph.drivers.cards.2.body':
    'Sobald das Konto Ihres Fahrers verknüpft ist, kann er mit der Zahlungsmethode Ihres Unternehmens für das geschäftliche Parken bezahlen.',
  'corporateAccounts.infograph.drivers.cards.2.title': 'Parken beginnen',
  'corporateAccounts.infograph.drivers.note':
    'Wichtig ist, dass die Fahrer ihr Firmenkonto mit ihrem persönlichen Konto verknüpfen. Denn die Einrichtung eines separaten Firmenkontos kostet unnötig Zeit und verursacht unnötigen Aufwand beim Parken.',
  'corporateAccounts.infograph.payments.cards.0.body':
    'Gestalten Sie die Abwicklung von Parkgebühren einfacher, indem Sie Ihren Fahrern eine ausgeblendete Zahlungskarte des Unternehmens zur Verfügung stellen.',
  'corporateAccounts.infograph.payments.cards.0.title': 'ZAHLUNGSKARTE HINZUFÜGEN',
  'corporateAccounts.infograph.payments.cards.1.body':
    'Vergeben Sie einen Namen für Ihre Zahlungskarte, so dass Ihre Mitarbeiter sie in der App leicht erkennen können.',
  'corporateAccounts.infograph.payments.cards.1.title': 'KARTENNAME FESTLEGEN',
  'corporateAccounts.infograph.payments.cards.2.body':
    'Nachdem Sie Ihre Karte gespeichert haben, ist sie auf dem Gerät Ihres Fahrers zugänglich.',
  'corporateAccounts.infograph.payments.cards.2.title': 'Parken beginnen',
  'corporateAccounts.infograph.payments.note':
    'Ihre Fahrer können nur den Namen Ihrer Karte sehen. Sie benötigen keine weiteren Karteninformationen oder CVV, um die Parkgebühr zu bezahlen.',
  'corporateAccounts.infograph.vehicles.cards.0.body':
    'Geben Sie ein Fahrzeug in das Portal ein, sodass Ihre Fahrer es mithilfe der PayByPhone-App parken können.',
  'corporateAccounts.infograph.vehicles.cards.0.title': 'EIN FAHRZEUG HINZUFÜGEN',
  'corporateAccounts.infograph.vehicles.cards.1.body':
    'Vergeben Sie einen Namen für Ihr Fahrzeug. Dieser wird nicht in der App angezeigt, hilft Ihnen aber beim Filtern Ihrer Liste.',
  'corporateAccounts.infograph.vehicles.cards.1.title': 'FAHRZEUGNAME FESTLEGEN',
  'corporateAccounts.infograph.vehicles.cards.2.body':
    'Nachdem Sie das Fahrzeug gespeichert haben, können Ihre Fahrer es suchen und zu ihrem App-Profil hinzufügen.',
  'corporateAccounts.infograph.vehicles.cards.2.title': 'Parken beginnen',
  'corporateAccounts.infograph.vehicles.note':
    'Ihre Fahrer können nur Firmenfahrzeuge hinzufügen, die in dieser Liste eingetragen sind. Fahrzeuge können über das Portal nicht mit einem bestimmten Fahrer verknüpft werden.',
  'corporateAccounts.invoiceIdValidation.invalidError': 'Keine gültige Salesforce-ID',
  'corporateAccounts.languages.de': 'Deutsch',
  'corporateAccounts.languages.fr': 'Französisch',
  'corporateAccounts.languages.gb': 'Englisch(GB)',
  'corporateAccounts.languages.glb': 'Global',
  'corporateAccounts.languages.it': 'Italienisch',
  'corporateAccounts.languages.nl': 'Niederländisch',
  'corporateAccounts.languages.us': 'Englisch',
  'corporateAccounts.menuLeft.account': 'Konto',
  'corporateAccounts.menuLeft.accounts': 'Konten',
  'corporateAccounts.menuLeft.adminUsers': 'Admin-Benutzer',
  'corporateAccounts.menuLeft.admins': 'Admin-Benutzer',
  'corporateAccounts.menuLeft.auditLogs': 'Prüfprotokolle',
  'corporateAccounts.menuLeft.billing': 'Abrechnung und Abonnement',
  'corporateAccounts.menuLeft.creditAccount': 'Guthabenkonto',
  'corporateAccounts.menuLeft.drivers': 'Fahrer',
  'corporateAccounts.menuLeft.monthlyStatements': 'Monatliche Abrechnung',
  'corporateAccounts.menuLeft.payments': 'Zahlungsmethoden',
  'corporateAccounts.menuLeft.reports': 'Berichte',
  'corporateAccounts.menuLeft.sessions': 'Parksitzungen',
  'corporateAccounts.menuLeft.staffUsers': 'PBP-Mitarbeiter',
  'corporateAccounts.menuLeft.superAdminHome': 'Superadmin-Home',
  'corporateAccounts.menuLeft.trigger': 'Seitenleiste ausblenden',
  'corporateAccounts.menuLeft.vehicles': 'Fahrzeuge',
  'corporateAccounts.month.1': 'Januar',
  'corporateAccounts.month.10': 'Oktober',
  'corporateAccounts.month.11': 'November',
  'corporateAccounts.month.12': 'Dezember',
  'corporateAccounts.month.2': 'Februar',
  'corporateAccounts.month.3': 'März',
  'corporateAccounts.month.4': 'April',
  'corporateAccounts.month.5': 'Mai',
  'corporateAccounts.month.6': 'Juni',
  'corporateAccounts.month.7': 'Juli',
  'corporateAccounts.month.8': 'August',
  'corporateAccounts.month.9': 'September',
  'corporateAccounts.monthlyStatements.downloadStatement':
    'Abrechnung für Parkvorgänge herunterlade',
  'corporateAccounts.monthlyStatements.generatedOn': 'Erstellt am',
  'corporateAccounts.monthlyStatements.title': 'Monatliche Abrechnung',
  'corporateAccounts.notFound': 'Seite nicht gefunden',
  'corporateAccounts.notification.reportReady.description':
    'Exportierte Monatsberichte werden für 72 Stunden unter „Monatsberichte“ verfügbar sein.',
  'corporateAccounts.notification.reportReady.title': 'Ihr Bericht ist fertig',
  'corporateAccounts.notify.error.auditLogs':
    'Beim Abrufen der Audit-Protokolle ist ein Fehler aufgetreten',
  'corporateAccounts.notify.error.downloadMonthlyStatement':
    'Beim Herunterladen der monatlichen Abrechnung für deine Parkvorgänge ist ein Fehler aufgetreten.',
  'corporateAccounts.notify.error.getMonthlyStatements':
    'Beim Laden der monatlichen Abrechnung für deine Parkvorgänge ist ein Fehler aufgetreten.',
  'corporateAccounts.onboarding.actions.openPayByPhone': 'PayByPhone öffnen',
  'corporateAccounts.onboarding.activationFailed': 'Dein Konto konnte nicht verknüpft werden',
  'corporateAccounts.onboarding.activationFailed.message':
    'Aufgrund eines technischen Problems konnte dein Konto nicht verknüpft werden. Bitte versuche es später erneut. Wenn der Fehler weiterhin besteht, kontaktiere deinen Manager.',
  'corporateAccounts.onboarding.addDriverStep.description':
    'Füge deinem Unternehmenskonto Fahrer hinzu.',
  'corporateAccounts.onboarding.addDriverStep.title': 'Lade Fahrer ein',
  'corporateAccounts.onboarding.addVehicleStep.description':
    'Erstelle eine Liste der autorisierten Geschäftsfahrzeuge.',
  'corporateAccounts.onboarding.addVehicleStep.title': 'Fahrzeuge hinzufügen',
  'corporateAccounts.onboarding.corporationNotFound': 'Firma ist nicht vorhanden',
  'corporateAccounts.onboarding.corporationNotFound.message':
    'Die Firma konnte nicht gefunden werden. Bitte versuche es später erneut. Wenn das Problem weiterhin besteht, kontaktiere deinen Manager, um Unterstützung bei der Lösung des Problems zu erhalten.',
  'corporateAccounts.onboarding.driverAlreadyActive': 'Konto bereits verknüpft',
  'corporateAccounts.onboarding.driverAlreadyActive.message':
    'Dein Konto ist bereits mit einem Unternehmen verknüpft.',
  'corporateAccounts.onboarding.driverNotFound': 'Fahrer nicht gefunden!',
  'corporateAccounts.onboarding.driverNotFound.message':
    'Der Fahrer wurde nicht gefunden. Bitte versuche es später erneut. Wenn das Problem weiterhin besteht, kontaktiere deinen Manager, um Unterstützung bei der Lösung des Problems zu erhalten.',
  'corporateAccounts.onboarding.internalError': 'Es ist ein Fehler aufgetreten',
  'corporateAccounts.onboarding.internalError.message':
    'Deine Anfrage konnte nicht bearbeitet werden. Bitte versuche es später erneut. Wenn das Problem weiterhin besteht, kontaktiere deinen Manager, um Unterstützung bei der Lösung des Problems zu erhalten.',
  'corporateAccounts.onboarding.invitationExpired': 'Einladungslink abgelaufen',
  'corporateAccounts.onboarding.invitationExpired.message':
    'Bitte deine Firma, die Einladung erneut zu senden.',
  'corporateAccounts.onboarding.inviteCodeNotFound': 'Einladungscode wurde nicht gefunden',
  'corporateAccounts.onboarding.inviteCodeNotFound.message':
    'Wende dich an deinen Manager, um einen neuen Link zum Abschluss des Aktivierungsprozesses zu erhalten.',
  'corporateAccounts.onboarding.inviteCodeNotValid': 'Einladungscode ist ungültig',
  'corporateAccounts.onboarding.inviteCodeNotValid.message':
    'Wende dich an deinen Manager, um einen neuen Link zum Abschluss des Aktivierungsprozesses zu erhalten.',
  'corporateAccounts.onboarding.logInToStartParking':
    'Melde dich bei PayByPhone an, um mit dem Parken zu beginnen!',
  'corporateAccounts.onboarding.progressBar.description':
    'Wir haben eine Checkliste erstellt, um dir den Einstieg zu erleichtern.',
  'corporateAccounts.onboarding.progressBar.title': "Los geht's!",
  'corporateAccounts.onboarding.registration.formDescription':
    '{companyName} hat dich eingeladen, die PayByPhone-App zum Parken zu verwenden.',
  'corporateAccounts.onboarding.registration.formTitle': 'Schließe deine Registrierung ab',
  'corporateAccounts.onboarding.registrationRequired': 'Konto nicht gefunden',
  'corporateAccounts.onboarding.registrationRequired.message':
    'Du benötigst ein PayByPhone-Konto, um die Einladung annehmen zu können. Please sign up for PayByPhone and ask your company to invite you again. Wenn der Fehler weiterhin besteht, kontaktiere deinen Manager.',
  'corporateAccounts.onboarding.setupAccountStep.description':
    'Unternehmensname und -adresse für die Rechnungsstellung',
  'corporateAccounts.onboarding.setupAccountStep.title': 'Konto einrichten',
  'corporateAccounts.onboarding.setupPaymentStep.description':
    'Deine Fahrer nutzen diese Zahlungsmethode, um die Parkgebühren zu bezahlen.',
  'corporateAccounts.onboarding.setupPaymentStep.title': 'Gib die Zahlungsdetails ein',
  'corporateAccounts.onboarding.socialSignIn.instruction':
    'Wenn du über ein bestehendes Konto verfügst und dasselbe Konto verwenden möchtest, melde dich an mit',
  'corporateAccounts.onboarding.success': 'Konto erfolgreich verknüpft',
  'corporateAccounts.onboarding.success.carousel1.text':
    'Ihre Firmenfahrzeuge werden als „Business Approved“ gekennzeichnet',
  'corporateAccounts.onboarding.success.carousel1.title': 'Firmenfahrzeuge hinzufügen',
  'corporateAccounts.onboarding.success.carousel2.text':
    'Die Zahlungsart ist bereits eingerichtet. Sie müssen sie nicht manuell hinzufügen.',
  'corporateAccounts.onboarding.success.carousel2.title': 'Unternehmenszahlung für Parkgebühren',
  'corporateAccounts.onboarding.success.carousel3.subText':
    '*Je nach Region können SMS-Gebühren anfallen.',
  'corporateAccounts.onboarding.success.carousel3.text':
    'Wir empfehlen, diese zu aktivieren, um Bußgelder zu vermeiden.',
  'corporateAccounts.onboarding.success.carousel3.title': 'SMS-Erinnerungen',
  'corporateAccounts.onboarding.success.message':
    'Dein Konto wurde mit {companyName} verknüpft. Melde dich bei PayByPhone an, um mit dem Parken zu beginnen.',
  'corporateAccounts.onboarding.success.notification':
    'Konto erfolgreich erstellt! Ihr Konto ist jetzt mit {companyName} verknüpft!',
  'corporateAccounts.onboarding.watchProductDemoVideo': 'Schau dir unser Produktdemovideo an.',
  'corporateAccounts.onboarding.welcomeModal.actions.getStarted': "Los geht's!",
  'corporateAccounts.onboarding.welcomeModal.description':
    'PayByPhone Business ist einfach einzurichten und zu verwenden. Sieh dir das Video an, um das Portal kennenzulernen.',
  'corporateAccounts.onboarding.welcomeModal.frequentlyAskedQuestions': 'Sieh dir unsere FAQs an',
  'corporateAccounts.onboarding.welcomeModal.title': 'Erste Schritte mit PayByPhone Business',
  'corporateAccounts.pageDeprecated':
    'Die Seite ist veraltet, gelöscht oder überhaupt nicht vorhanden',
  'corporateAccounts.passwordValidation.defaultError':
    'Das eingegebene Kennwort entspricht nicht den Anforderungen. Bitte versuche es erneut',
  'corporateAccounts.passwordValidation.lengthError':
    'Das Kennwort muss zwischen 8 und 51 Zeichen lang sein.',
  'corporateAccounts.passwordValidation.lowerCaseError':
    'Das Kennwort muss mindestens einen Kleinbuchstaben enthalten.',
  'corporateAccounts.passwordValidation.missingNumberError':
    'Das Kennwort muss mindestens eine Zahl enthalten.',
  'corporateAccounts.passwordValidation.missingSpecialCharacterError':
    'Das Kennwort muss mindestens ein Sonderzeichen enthalten.',
  'corporateAccounts.passwordValidation.upperCaseError':
    'Das Kennwort muss mindestens einen Großbuchstaben enthalten.',
  'corporateAccounts.payByPhone': 'PayByPhone Technologies',
  'corporateAccounts.payment.actions.restrictedEdit.content':
    'Diese Funktion ist vorübergehend eingeschränkt. Ihr PaybyPhone-Kundenbetreuer kann Ihnen jedoch bei diesem Vorgang behilflich sein, sodass Ihre Mitarbeiter/innen sofort  eine neue Zahlungsmethode nutzen können!',
  'corporateAccounts.payment.actions.restrictedEdit.title': 'Aktualisierungen Zahlungsmethode',
  'corporateAccounts.paymentMethod.add.success': 'Zahlung hinzugefügt',
  'corporateAccounts.paymentMethod.delete.success': 'Zahlung entfernt',
  'corporateAccounts.paymentMethod.infograph.cards.0.title': 'Neue Zahlungsmethode hinzufügen',
  'corporateAccounts.paymentMethod.infograph.cards.1.body':
    'Optional: Aktualisieren Sie den Namen Ihrer Zahlungsmethode über das Kartenbearbeitungsmenü.',
  'corporateAccounts.paymentMethod.infograph.cards.1.title': 'Kartennamen aktualisieren',
  'corporateAccounts.paymentMethod.infograph.cards.2.body':
    'Aktivierte Fahrer sehen diese Zahlungsmethoden automatisch in ihrer App.',
  'corporateAccounts.paymentMethod.infograph.cards.2.title': 'Automatische Freigabe',
  'corporateAccounts.paymentMethod.infograph.subcontent':
    'Die Zahlungsmethoden können nur von Fahrern verwendet werden, die mit „Business Approved“-Fahrzeugen für das Parken bezahlen. Dies sind die zu Ihrer Fahrzeugliste hinzugefügten Fahrzeuge.',
  'corporateAccounts.paymentMethod.update.success': 'Zahlung aktualisiert',
  'corporateAccounts.payments.actions.addAndAgree': 'Hinzufügen und bestätigen',
  'corporateAccounts.payments.actions.addPayment': 'Zahlung hinzufügen',
  'corporateAccounts.payments.actions.editPayment': 'Zahlung bearbeiten',
  'corporateAccounts.payments.cardCountryCode': 'Ländercode der Karte',
  'corporateAccounts.payments.cardCvc': 'Karte CVC',
  'corporateAccounts.payments.cardName': 'Name der Karte',
  'corporateAccounts.payments.cardNumber': 'Kartennummer',
  'corporateAccounts.payments.cardType': 'Kartentyp',
  'corporateAccounts.payments.create.error.cardAlreadyExpired': 'Die Karte ist abgelaufen.',
  'corporateAccounts.payments.create.error.cardNumberInvalid': 'Ungültige Kartennummer.',
  'corporateAccounts.payments.create.error.cardNumberInvalidFormat':
    'Ungültiges Kartennummernformat.',
  'corporateAccounts.payments.create.error.cardNumberRequired': 'Kartennummer ist erforderlich.',
  'corporateAccounts.payments.create.error.cardTypeNotSupported':
    'Kartentyp wird nicht unterstützt.',
  'corporateAccounts.payments.create.error.cardValidationFailed':
    'Kartenüberprüfung fehlgeschlagen.',
  'corporateAccounts.payments.create.error.countryCodeNotSupported':
    'Ländercode wird nicht unterstützt.',
  'corporateAccounts.payments.create.error.countryCodeRequired': 'Ländercode ist erforderlich.',
  'corporateAccounts.payments.create.error.cvvInvalidFormat': 'Ungültiges CVV-Format.',
  'corporateAccounts.payments.create.error.cvvRequired': 'CVV ist erforderlich.',
  'corporateAccounts.payments.create.error.expiryMonthInvalid': 'Ablaufmonat ist ungültig.',
  'corporateAccounts.payments.create.error.expiryMonthRequired': 'Ablaufmonat ist erforderlich.',
  'corporateAccounts.payments.create.error.expiryYearRequired': 'Ablaufjahr ist erforderlich.',
  'corporateAccounts.payments.create.error.expiryYearTooFarInTheFuture':
    'Ablaufjahr liegt zu weit in der Zukunft.',
  'corporateAccounts.payments.create.error.limitExceeded':
    'Du hast das Limit deines Zahlungskontos erreicht. Wenn du mehr darüber erfahren möchtest, wie du dein Limit erhöhen kannst, wende dich bitte an den Vertrieb.',
  'corporateAccounts.payments.create.error.nameOnCardInvalidFormat':
    'Der Name auf der Karte ist ungültig.',
  'corporateAccounts.payments.create.error.nameOnCardRequired':
    'Der Name auf der Karte ist erforderlich.',
  'corporateAccounts.payments.create.error.paymentAccountAlreadyExists':
    'Zahlungskonto ist bereits vorhanden.',
  'corporateAccounts.payments.create.error.paymentAccountNotFound':
    'Zahlungskonto konnte nicht gefunden werden.',
  'corporateAccounts.payments.create.error.unhandledError':
    'Wir konnten deine Zahlungskarte nicht hinzufügen. Bitte versuche es erneut.',
  'corporateAccounts.payments.create.error.zipCodeInvalidFormat':
    'Ungültiges Format für Postleitzahl.',
  'corporateAccounts.payments.create.success':
    'Zahlungsart hinzugefügt. Es kann bis zu einer Minute dauern, bis neue Zahlungsmethoden in der Liste der Zahlungsarten angezeigt werden.',
  'corporateAccounts.payments.cvc': 'CVV/CVC',
  'corporateAccounts.payments.description':
    'Aktivierte Fahrer sehen diese Zahlungsmethoden automatisch in ihren Apps, sie müssen keine Kartendaten manuell eingeben. Diese Zahlungsmethoden können nur verwendet werden,',
  'corporateAccounts.payments.description.link':
    'um die Parkgebühren von Fahrzeugen zu bezahlen, die von Unternehmen zugelassen wurden.',
  'corporateAccounts.payments.emptyState.addCreditCardBtn': 'Kreditkarte hinzufügen',
  'corporateAccounts.payments.emptyState.description':
    'Die Kreditkarte wird nur für die Bezahlung der Parkgebühren verwendet. Eine separate Rechnung mit Abonnementgebühren wird dir zu Beginn jedes Monats per E-Mail zugesandt. Die Karte wird erst belastet, wenn deine Fahrer mit dem Parken beginnen.',
  'corporateAccounts.payments.emptyState.title': 'Eine Zahlungsmethode hinzufügen',
  'corporateAccounts.payments.emptyState.videoLink': 'So fügt man eine Kreditkarte hinzu',
  'corporateAccounts.payments.error.delete.notFound':
    'Die Zahlungsart wurde nicht gefunden, bitte aktualisieren Sie Ihren Browser.',
  'corporateAccounts.payments.expiry': 'Ablauf',
  'corporateAccounts.payments.expiryMonth': 'Ablaufmonat',
  'corporateAccounts.payments.expiryYear': 'Ablaufjahr',
  'corporateAccounts.payments.form.cardCountryCodePlaceholder': 'Wählen Sie einen Ländercode',
  'corporateAccounts.payments.form.cardCountryCodeRequired': 'Bitte wählen Sie einen Ländercode',
  'corporateAccounts.payments.form.cardCvcRequired': 'CVC ist erforderlich',
  'corporateAccounts.payments.form.cardCvcValidation': 'CVC ist ungültig',
  'corporateAccounts.payments.form.cardExpiryRequired': 'Ablaufdatum ist Pflichtfeld',
  'corporateAccounts.payments.form.cardExpiryValidation': 'Das Ablaufdatum ist ungültig',
  'corporateAccounts.payments.form.cardNameLength':
    'Der Kartenname darf nicht mehr als {limit} Zeichen enthalten',
  'corporateAccounts.payments.form.cardNamePlaceholder': 'Einen Kartennamen hinzufügen',
  'corporateAccounts.payments.form.cardNameRequired': 'Bitte fügen Sie einen Kartennamen hinzu',
  'corporateAccounts.payments.form.cardNumberPlaceholder': 'Kartennummer',
  'corporateAccounts.payments.form.cardNumberRequired': 'Kreditkartennummer ist Pflichtfeld',
  'corporateAccounts.payments.form.cardNumberValidation': 'Kreditkarte ist ungültig',
  'corporateAccounts.payments.form.cardTypePlaceholder': 'Eine Kartenart hinzufügen',
  'corporateAccounts.payments.form.cardTypeRequired': 'Bitte wählen Sie eine Kartenart',
  'corporateAccounts.payments.form.creditCardTypeNotSupported':
    '{creditCardType} wird nicht unterstützt',
  'corporateAccounts.payments.form.disclaimer':
    'Platzhalter zur Aktualisierung des Karten-Haftungsausschlusses',
  'corporateAccounts.payments.form.expiryMonthPlaceholder': 'Ablaufmonat auswählen',
  'corporateAccounts.payments.form.expiryYearPlaceholder': 'JJJJ',
  'corporateAccounts.payments.form.nameOnCardFormat':
    "Der Name auf der Karte darf keine ungültigen Zeichen wie < > ' “ oder & enthalten",
  'corporateAccounts.payments.form.nameOnCardLength':
    'Der Name auf der Karte darf nicht weniger als 2 oder mehr als 70 Zeichen enthalten',
  'corporateAccounts.payments.form.nameOnCardPlaceholder': 'Name auf der Karte hinzufügen',
  'corporateAccounts.payments.form.nameOnCardRequired': 'Bitte geben Sie den Kartennamen ein',
  'corporateAccounts.payments.form.primaryCountryOfUse': 'Vorrangiges Einsatzland',
  'corporateAccounts.payments.form.storedCredentialAgreement':
    'Vereinbarung über gespeicherte Zugangsdaten',
  'corporateAccounts.payments.form.termsAgreement':
    'Durch Hinzufügen dieser Zahlungsart erklären Sie sich mit unseren/unserer',
  'corporateAccounts.payments.form.update': 'Kreditkartendaten aktualisieren',
  'corporateAccounts.payments.form.zipCodePlaceholder': 'Postleitzahl hinzufügen',
  'corporateAccounts.payments.form.zipCodeRequired': 'Bitte geben Sie eine Postleitzahl an',
  'corporateAccounts.payments.generic.error':
    'Bei der Aktualisierung der von Ihnen genutzten Zahlungsart ist ein Problem aufgetreten',
  'corporateAccounts.payments.main.description':
    'Platzhalter für die Beschreibung von Zahlungsmethoden',
  'corporateAccounts.payments.modal.delete.areYouSure':
    'Möchten Sie die folgende Zahlungsart wirklich löschen?',
  'corporateAccounts.payments.modal.delete.lastCard':
    'Ihre Fahrer können dann keine Parkgebühren mehr über Ihr Unternehmen abrechnen!',
  'corporateAccounts.payments.nameOnCard': 'Name auf der Karte',
  'corporateAccounts.payments.paymentAccountId': 'Zahlungskonto-ID',
  'corporateAccounts.payments.spa.challenge.title': 'SCA-Challenge-Fluss',
  'corporateAccounts.payments.spa.title': 'Neue Zahlungsmethode hinzufügen',
  'corporateAccounts.payments.spa.title.edit': 'Zahlungsart bearbeiten',
  'corporateAccounts.payments.spa.verification.content':
    'Aus Sicherheitsgründen werden Sie zu Ihrer Bank weitergeleitet, bevor diese Kreditkarte hinzugefügt wird.',
  'corporateAccounts.payments.spa.verification.title': 'Prüfung erforderlich',
  'corporateAccounts.payments.title': 'Zahlungsmethoden',
  'corporateAccounts.payments.toast.paymentMethodDeleted': 'Zahlungsart entfernt',
  'corporateAccounts.payments.update.success': 'Kartenname aktualisiert',
  'corporateAccounts.payments.zipCode': 'Postleitzahl',
  'corporateAccounts.reports.actions.clear': 'Löschen',
  'corporateAccounts.reports.actions.load': 'Laden',
  'corporateAccounts.reports.cardName': 'Name der Karte',
  'corporateAccounts.reports.cost': 'Gesamtsumme',
  'corporateAccounts.reports.costCenter': 'Kostenstelle',
  'corporateAccounts.reports.costInfo':
    'Die Parkkosten sind erst final, wenn die Zahlung erfasst wurde.',
  'corporateAccounts.reports.dateRange': 'Datumsbereich',
  'corporateAccounts.reports.dateRange.endDate': 'Enddatum',
  'corporateAccounts.reports.dateRange.startDate': 'Anfangsdatum',
  'corporateAccounts.reports.day': 'Wochentag',
  'corporateAccounts.reports.dayOfWeek': 'Tag',
  'corporateAccounts.reports.email': 'E-Mail',
  'corporateAccounts.reports.firstName': 'Vorname',
  'corporateAccounts.reports.lastName': 'Nachname',
  'corporateAccounts.reports.licensePlate': 'Kennzeichen',
  'corporateAccounts.reports.localEndDateTime': 'Ende',
  'corporateAccounts.reports.localStartDateTime': 'Start',
  'corporateAccounts.reports.lotId': 'Gebührensatzcode',
  'corporateAccounts.reports.main.title': 'Berichte',
  'corporateAccounts.reports.maskedCardNumber': 'Kreditkartennummer ausgeblendet',
  'corporateAccounts.reports.month': 'Monat',
  'corporateAccounts.reports.month.placeholder': 'Bitte auswählen',
  'corporateAccounts.reports.monthly': 'Monatlich',
  'corporateAccounts.reports.monthly.generateReport': 'Bericht erstellen',
  'corporateAccounts.reports.monthly.infoNotice':
    'Exportierte Monatsberichte stehen hier für 72 Stunden zur Verfügung.',
  'corporateAccounts.reports.monthly.last30days': 'Letzte 30 Tage',
  'corporateAccounts.reports.monthly.last7days': 'Letzte 7 Tage',
  'corporateAccounts.reports.monthly.last90days': 'Letzte 90 Tage',
  'corporateAccounts.reports.monthly.reportJob.status.completed': 'Abgeschlossen',
  'corporateAccounts.reports.monthly.reportJob.status.failed': 'Fehlgeschlagen',
  'corporateAccounts.reports.monthly.reportJob.status.pending': 'Ausstehend',
  'corporateAccounts.reports.monthly.reportType': 'Berichtstyp',
  'corporateAccounts.reports.monthly.timeExported': 'Exportzeit',
  'corporateAccounts.reports.monthly.toast.generatingReport.content':
    'Der Bericht wird erstellt und steht in Ihren Exporten zur Verfügung, wenn er fertig ist.',
  'corporateAccounts.reports.monthly.toast.generatingReport.title': 'Ihr Bericht wird vorbereitet',
  'corporateAccounts.reports.monthlyReports': 'Monatsberichte',
  'corporateAccounts.reports.netTotal': 'Betrag (netto)',
  'corporateAccounts.reports.parkingLotName': 'Name Parkplatz',
  'corporateAccounts.reports.parkingMinutes': 'Dauer',
  'corporateAccounts.reports.parkingRegionName': 'Parkmöglichkeit Region Name',
  'corporateAccounts.reports.parkingStatus': 'Parkstatus',
  'corporateAccounts.reports.parkingVendorName': 'Anbieter',
  'corporateAccounts.reports.paymentDate': 'Datum',
  'corporateAccounts.reports.paymentStatus': 'Status der Zahlung',
  'corporateAccounts.reports.phone': 'Telefon',
  'corporateAccounts.reports.phoneNumber': 'Telefonnummer',
  'corporateAccounts.reports.searchInput.placeholder':
    'Nach Kennzeichen, Vor- und Nachname des Fahrers, Name des Parkanbieters suchen',
  'corporateAccounts.reports.tax': 'MwSt.',
  'corporateAccounts.reports.transactionId': 'Transaktions-ID',
  'corporateAccounts.reports.transactions': 'Transaktionen',
  'corporateAccounts.reports.transactions.chooseDateRange': 'Wählen Sie einen Datumsbereich aus',
  'corporateAccounts.reports.transactions.noResults':
    'Keine Ergebnisse für den ausgewählten Datumsbereich',
  'corporateAccounts.reports.transactions.timeout.modal.content':
    'Versuchen Sie stattdessen, den Datumsbereich zu verkleinern oder einen Monatsbericht zu erstellen.',
  'corporateAccounts.reports.transactions.timeout.modal.title': 'Zeitüberschreitung',
  'corporateAccounts.reports.vehicleCountry': 'Fahrzeug Land',
  'corporateAccounts.reports.vehicleVin': 'Fahrgestellnummer des Fahrzeugs',
  'corporateAccounts.search.validation.minimum': 'Suche erfordert 3 Zeichen',
  'corporateAccounts.selfRegistration.actions.signIn': 'Anmelden',
  'corporateAccounts.selfRegistration.activation.description':
    'Dein Abrechnungszyklus beginnt heute. Wenn du Fragen hast, sind wir nur einen Klick entfernt. Kontaktiere uns über den Chat in der unteren rechten Ecke.',
  'corporateAccounts.selfRegistration.activation.feedback':
    'Wie einfach war es, Ihr Konto einzurichten?',
  'corporateAccounts.selfRegistration.activation.feedback.difficult': 'Schwierig',
  'corporateAccounts.selfRegistration.activation.feedback.easy': 'Einfach',
  'corporateAccounts.selfRegistration.activation.feedback.expected': 'Wie erwartet',
  'corporateAccounts.selfRegistration.activation.supportLink':
    'Klicke hier, um den Support zu kontaktieren',
  'corporateAccounts.selfRegistration.activation.title': 'Konto aktiviert! Du kannst jetzt parken',
  'corporateAccounts.selfRegistration.emailVerificationInstruction':
    'Prüfe deine E-Mails und klicke auf den Link, um dein Konto zu aktivieren. Wenn du innerhalb von 1 oder 2 Minuten keine E-Mail erhalten hast, sieh in deinem Spam-Ordner nach.',
  'corporateAccounts.selfRegistration.form.agreement':
    'Durch Aktivieren dieses Kästchens erkläre ich mich mit der {subscriptionAgreement} und der {privacyPolicy} einverstanden und akzeptiere diese.',
  'corporateAccounts.selfRegistration.form.creditCardNote':
    'Keine Kreditkarte erforderlich, bis du bereit bist zu parken',
  'corporateAccounts.selfRegistration.form.title': 'Erstelle dein PayByPhone Business-Konto',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.one':
    'Keine zeitraubenden Spesenabrechnungen mehr',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.three':
    'Fahrer zahlen für das Parken in nur 30 Sekunden',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.two':
    'Echtzeit-Transparenz darüber, wer wo und wann parkt',
  'corporateAccounts.selfRegistration.marketing.businessesTrust':
    'Große und kleine Unternehmen vertrauen darauf',
  'corporateAccounts.selfRegistration.marketing.title':
    'PayByPhone Business vereinfacht das Parkraummanagement für deine Flotte.',
  'corporateAccounts.selfRegistration.resendEmail.button': 'E-Mail erneut senden',
  'corporateAccounts.selfRegistration.resendEmail.failed':
    'Verifizierungs-E-Mail konnte nicht gesendet werden',
  'corporateAccounts.selfRegistration.resendEmail.success': 'Verifizierungs-E-Mail gesendet',
  'corporateAccounts.selfRegistration.success.supportContact': 'Support kontaktieren',
  'corporateAccounts.selfRegistration.success.supportMessage':
    'Hast du ein Problem? Wir helfen dir gerne!',
  'corporateAccounts.selfRegistration.success.title': 'E-Mail-Adresse verifizieren',
  'corporateAccounts.selfRegistration.verification.completeCaptcha':
    'Bitte fülle das Captcha unten aus',
  'corporateAccounts.selfRegistration.verification.failed':
    'Verifizierung konnte nicht durchgeführt werden',
  'corporateAccounts.selfRegistration.verification.failed.message':
    'Die Aktivierungsanfrage konnte zu diesem Zeitpunkt nicht abgeschlossen werden. Bitte versuche es später erneut.',
  'corporateAccounts.selfRegistration.verification.linkExpired':
    'Verifizierungslink ist abgelaufen',
  'corporateAccounts.selfRegistration.verification.linkExpired.message':
    'Die URL für die Einladung ist nicht mehr gültig.',
  'corporateAccounts.selfRegistration.verification.resendVerification':
    'Verifizierungs-E-Mail erneut senden',
  'corporateAccounts.selfRegistration.verification.success': 'Verifiziert',
  'corporateAccounts.selfRegistration.verification.success.message':
    'Du hast dein Konto erfolgreich verifiziert.',
  'corporateAccounts.selfRegistration.verification.success.portalLink':
    'Zum PayByPhone Business Portal wechseln',
  'corporateAccounts.server.domainErrors.RestrictedCorporateClient':
    'Der Zugriff auf den Unternehmenskunden ist aufgrund seines aktuellen Status eingeschränkt.',
  'corporateAccounts.sessions.main.description':
    'Sehen Sie sich die letzten 72 Stunden Parkzeit an.',
  'corporateAccounts.sessions.title': 'Parksitzungen',
  'corporateAccounts.staff.actions.add': 'Mitarbeiter hinzufügen',
  'corporateAccounts.staff.search': 'Nach Name, E-Mail oder ID suchen',
  'corporateAccounts.staff.title': 'Personalmanagement',
  'corporateAccounts.staff.title.add': 'Mitarbeiter hinzufügen',
  'corporateAccounts.subscription.cancelAccount': 'Musst du dein Konto schließen?',
  'corporateAccounts.subscription.contactCustomerSuccess':
    '{contact} Wende dich an unser Kundenerfolgsteam, wenn du dein Konto schließen musst.',
  'corporateAccounts.subscription.contactForPricing':
    'Wenden Sie sich an den Kundendienst, um Preisinformationen zu erhalten.',
  'corporateAccounts.subscription.currentPlanTitle': 'Aktueller Plan',
  'corporateAccounts.subscription.editPlan': 'Plan bearbeiten – Vertrieb kontaktieren',
  'corporateAccounts.subscription.error.contactSupport':
    'Preisinformationen können nicht geladen werden. Wenden Sie sich an den Kundendienst.',
  'corporateAccounts.subscription.error.tryAgain':
    'Preisinformationen können nicht geladen werden. Bitte warten Sie einen Moment und versuchen Sie es erneut.',
  'corporateAccounts.subscription.memberSince': 'Mitglied seit',
  'corporateAccounts.subscription.monthlyPlan': 'Monatliche Abrechnung',
  'corporateAccounts.subscription.nextBillingCycle': 'Nächster Abrechnungszyklus',
  'corporateAccounts.subscription.perDriver': 'pro Fahrer',
  'corporateAccounts.subscription.terms':
    'PayByPhone {termsAndConditions} und {privacyPolicy} für Business-Abonnement',
  'corporateAccounts.topBar.helpMenu.title': 'Benötigen Sie Hilfe?',
  'corporateAccounts.topBar.profileMenu.account': 'Konto',
  'corporateAccounts.topBar.profileMenu.changeCorporations': 'Zwischen den Konten wechseln',
  'corporateAccounts.topBar.profileMenu.contact': 'Kontaktiere uns',
  'corporateAccounts.topBar.profileMenu.editProfile': 'Profil bearbeiten',
  'corporateAccounts.topBar.profileMenu.email': 'E-Mail',
  'corporateAccounts.topBar.profileMenu.hello': 'Hallo',
  'corporateAccounts.topBar.profileMenu.help': 'Hilfe-Center',
  'corporateAccounts.topBar.profileMenu.home': 'Startseite',
  'corporateAccounts.topBar.profileMenu.phone': 'Telefon',
  'corporateAccounts.topBar.profileMenu.role': 'Rolle',
  'corporateAccounts.topBar.profileMenu.signOut': 'Abmeldung',
  'corporateAccounts.topBar.profileMenu.support': 'Support',
  'corporateAccounts.user.activation.error':
    'Bei der Aktivierung ist ein Problem aufgetreten. Bitte wende dich an deinen Administrator.',
  'corporateAccounts.user.activation.success': 'Du hast dein Konto erfolgreich aktiviert.',
  'corporateAccounts.user.limitExceeded.error':
    'Du hast dein Benutzerlimit erreicht. Wenn du mehr darüber erfahren möchtest, wie du dein Limit erhöhen kannst, wende dich bitte an den Vertrieb.',
  'corporateAccounts.user.login.success': 'Du hast dich erfolgreich angemeldet.',
  'corporateAccounts.user.registration.duplicateEmail':
    'Ein Benutzerprofil mit dieser E-Mail-Adresse existiert bereits.',
  'corporateAccounts.user.registration.internalError':
    'Bei der Bearbeitung deiner Anfrage ist ein Problem aufgetreten. Bitte versuche es später erneut. Wenn das Problem weiterhin besteht, wende dich bitte an den PayByPhone-Vertrieb, um Hilfe zu erhalten.',
  'corporateAccounts.user.registration.userEmailDomainNotValid':
    'Die Registrierung von dieser Domain ist blockiert. Versuche es bitte erneut mit einer anderen E-Mail-Adresse.',
  'corporateAccounts.users.role.customer_success': 'Kundenerfolg',
  'corporateAccounts.users.role.customer_support_tier_2': 'Kundensupport Stufe 2',
  'corporateAccounts.users.role.super_admin': 'Super-Admin',
  'corporateAccounts.vehicle.add.duplicate.error':
    'Ein Fahrzeug mit diesem Kennzeichen ist bereits vorhanden.',
  'corporateAccounts.vehicle.add.error': 'Wir konnten dein Fahrzeug nicht hinzufügen.',
  'corporateAccounts.vehicle.add.invalidCharacters': 'Kennzeichen enthält ungültige Zeichen',
  'corporateAccounts.vehicle.add.success': 'Fahrzeug hinzugefügt',
  'corporateAccounts.vehicle.delete.error': 'Wir konnten dein Fahrzeug nicht löschen.',
  'corporateAccounts.vehicle.delete.success': 'Fahrzeug gelöscht',
  'corporateAccounts.vehicle.limitExceeded.error':
    'Du hast dein Fahrzeuglimit überschritten. Wenn du mehr darüber erfahren möchtest, wie du dein Limit erhöhen kannst, wende dich bitte an den Vertrieb.',
  'corporateAccounts.vehicle.update.error': 'Wir konnten dein Fahrzeug nicht aktualisieren.',
  'corporateAccounts.vehicle.update.success': 'Fahrzeug aktualisiert',
  'corporateAccounts.vehicles.actions.addVehicle': 'Fahrzeug hinzufügen',
  'corporateAccounts.vehicles.actions.deleteVehicleMessage': 'Dieses Fahrzeug löschen?',
  'corporateAccounts.vehicles.actions.deleteVehicles': 'Fahrzeuge löschen',
  'corporateAccounts.vehicles.bulkDelete.success':
    '{numOfDeletedVehicles} Fahrzeuge wurden gelöscht',
  'corporateAccounts.vehicles.country': 'Land',
  'corporateAccounts.vehicles.csvDownload.country': 'Land (erforderlich)',
  'corporateAccounts.vehicles.csvDownload.jurisdiction': 'Bundesland/Provinz (erforderlich)',
  'corporateAccounts.vehicles.csvDownload.licensePlate': 'Kfz-Kennzeichen (erforderlich)',
  'corporateAccounts.vehicles.csvDownload.vin': 'VIN [Fahrzeugidentifizierungsnummer] (optional)',
  'corporateAccounts.vehicles.emptyState.ImportVehiclesBtn': 'Fahrzeuge importieren',
  'corporateAccounts.vehicles.emptyState.description':
    'Erstelle eine Liste der vom Unternehmen genehmigten Fahrzeuge, die mit einer Geschäftszahlungsmethode geparkt werden können.',
  'corporateAccounts.vehicles.emptyState.videoLink': 'So fügt man Fahrzeuge hinzu',
  'corporateAccounts.vehicles.export.error.modal.description':
    'Die Fahrzeugliste konnte nicht exportiert werden. Bitte versuchen Sie es erneut.',
  'corporateAccounts.vehicles.export.error.modal.title': 'Fehler beim Export der Fahrzeugliste',
  'corporateAccounts.vehicles.export.fileName': 'Fahrzeugliste',
  'corporateAccounts.vehicles.export.modal.description':
    'Exportierte Daten werden basierend auf den Filtern generiert, die derzeit auf Ihre Fahrzeugliste angewendet werden.',
  'corporateAccounts.vehicles.export.modal.title': 'Fahrzeugliste exportieren',
  'corporateAccounts.vehicles.export.success.notification.description':
    'Ihre Fahrzeugliste wurde exportiert.',
  'corporateAccounts.vehicles.export.success.notification.title': 'Fahrzeugliste exportiert',
  'corporateAccounts.vehicles.form.add': 'Fahrzeug hinzufügen',
  'corporateAccounts.vehicles.form.countryRequired': 'Bitte wählen Sie ein Land aus',
  'corporateAccounts.vehicles.form.edit': 'Fahrzeug bearbeiten',
  'corporateAccounts.vehicles.form.licensePlatePlaceholder': 'Nummernschild hinzufügen',
  'corporateAccounts.vehicles.form.licensePlateRequired': 'Bitte geben Sie das Kennzeichen ein',
  'corporateAccounts.vehicles.form.licensePlateValidation':
    'Das Kennzeichen scheint nicht gültig zu sein',
  'corporateAccounts.vehicles.form.new': 'Neues Fahrzeug hinzufügen',
  'corporateAccounts.vehicles.form.regionRequired': 'Bitte wählen Sie eine Region aus',
  'corporateAccounts.vehicles.form.update': 'Fahrzeug aktualisieren',
  'corporateAccounts.vehicles.form.vinValidation': 'Keine gültige FIN-Nummer',
  'corporateAccounts.vehicles.import.description':
    'Führen Sie die folgenden Schritte aus, um Ihre Fahrer in Ihr Konto hochzuladen.',
  'corporateAccounts.vehicles.import.fileName': 'Fahrzeugimport',
  'corporateAccounts.vehicles.import.header.countryISO': 'Land ISO-3166 Alpha-2 (erforderlich)',
  'corporateAccounts.vehicles.import.header.licensePlate': 'Kfz-Kennzeichen (erforderlich)',
  'corporateAccounts.vehicles.import.header.numberPlate': 'Nummernschild (erforderlich)',
  'corporateAccounts.vehicles.import.header.stateISO':
    'Bundesland ISO 3166-2:US-Provinz ISO 3166-2:CA (erforderlich)',
  'corporateAccounts.vehicles.import.header.vin': 'FIN [Fahrzeug-Identifikationsnummer]',
  'corporateAccounts.vehicles.import.step.2.content': 'Vorlage ausfüllen und speichern.',
  'corporateAccounts.vehicles.import.title': 'Fahrzeuge - Massen-Upload',
  'corporateAccounts.vehicles.infograph.cards.0.title': 'Fahrzeuge hinzufügen',
  'corporateAccounts.vehicles.infograph.cards.1.body':
    'Bitten Sie Ihre Fahrer, die entsprechenden Fahrzeuge zu ihrer PayByPhone-App hinzuzufügen.',
  'corporateAccounts.vehicles.infograph.cards.1.title': 'Ihre Fahrer benachrichtigen',
  'corporateAccounts.vehicles.infograph.cards.2.body':
    'Aktive Fahrer können mit Ihrer Zahlungsmethode für das Parken mit diesem Fahrzeug bezahlen.',
  'corporateAccounts.vehicles.infograph.cards.2.title': 'Parken beginnen',
  'corporateAccounts.vehicles.infograph.subcontent':
    'Fahrer können mit Ihrer Zahlungsmethode nicht für das Parken mit einem Fahrzeug bezahlen, das nicht in dieser Liste aufgeführt ist. Fahrzeuge sind nicht mit einem bestimmten Fahrer verknüpfbar.',
  'corporateAccounts.vehicles.licensePlate': 'Nummernschild',
  'corporateAccounts.vehicles.modal.content.bulkDelete':
    'Möchten Sie die ausgewählten Fahrzeuge wirklich löschen?',
  'corporateAccounts.vehicles.modal.title.bulkDelete': 'Fahrzeuge löschen?',
  'corporateAccounts.vehicles.title': 'Fahrzeuge',
  'corporateAccounts.vehicles.vin': 'Fahrgestellnummer',
  'corporateAcctounts.vehicles.description':
    'Fahrer müssen diese Fahrzeuge ihrer PayByPhone-App hinzufügen, sie werden nicht automatisch angezeigt.',
};
